import { createApp } from 'vue'
import { loadFonts } from './plugins/webfontloader'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from 'vue-toastification'
import VueDatePicker from '@vuepic/vue-datepicker'

import 'vue-toastification/dist/index.css'
import '@vuepic/vue-datepicker/dist/main.css'

library.add(fas)

loadFonts()

const app = createApp(App)
app.use(router)
app.use(store)
app.use(vuetify)
app.use(Toast)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('VueDatePicker', VueDatePicker)
app.mount('#app')
