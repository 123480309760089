<template>
  <div :style="{
    display: isMobile ? 'flex' : 'flex',
    justifyContent: isMobile ? '' : 'space-between',
    flexDirection: isMobile ? 'column' : 'row',
  }">
    <div :style="{
      width: isMobile ? '100%' : '50%',
    }"
    >
      <v-card
        class="card"
        :style="{
          width: isMobile ? '100%' : '100%',
          padding: isMobile ? '0px' : '0px',
          height: isMobile ? '200px' : '300px',
          display: isMobile ? '' : 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center'
        }"
        style="
        background-color: #F4F4F7;
        "
        elevation="0"
      >
        <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <v-img
            cover
            src="https://cdn.samjinnetworks.com/images/web/banner/banner5_1.jpg"
            :style="{
              alignItems: 'center',
              textAlign: 'center',
              color: 'white',
              fontSize: '35px',
              fontWeight: '900',
              borderRadius: isMobile ? '0px' : '40px',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
              filter: brightnessStyle // 밝기를 동적으로 변경하기 위한 스타일 속성
            }"
            @mouseover="changeBrightness(100)"
            @mouseleave="changeBrightness(70)"
          >
            <div style="filter: none;">Content Creation</div>
          </v-img>
        </div>
      </v-card>
    </div>

    <div :style="{
      width: isMobile ? '100%' : '50%',
    }"
    >
      <v-card
        class="card"
        :style="{
          width: isMobile ? '100%' : '100%',
          padding: isMobile ? '0px' : '0px',
          height: isMobile ? '200px' : '300px',
          display: isMobile ? '' : 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center'
        }"
        style="
        background-color: #F4F4F7;
        "
        elevation="0"
      >
        <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <v-img
            cover
            src="https://cdn.samjinnetworks.com/images/web/banner/banner5_2.jpg"
            :style="{
              alignItems: 'center',
              textAlign: 'center',
              color: 'white',
              fontSize: '35px',
              fontWeight: '900',
              borderRadius: isMobile ? '0px' : '40px',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
              filter: brightnessStyle2 // 밝기를 동적으로 변경하기 위한 스타일 속성
            }"
            @mouseover="changeBrightness2(100)"
            @mouseleave="changeBrightness2(70)"
          >
            <div style="filter: none;">Engineering</div>
          </v-img>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
  data() {
    return {
      brightnessStyle: 'brightness(70%)',
      brightnessStyle2: 'brightness(70%)'
    };
  },
  methods: {
    changeBrightness(brightness) {
      this.brightnessStyle = `brightness(${brightness}%)`; // 밝기 스타일 변경
    },
    changeBrightness2(brightness) {
      this.brightnessStyle2 = `brightness(${brightness}%)`; // 밝기 스타일 변경
    }
  },
  setup() {
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value)
    };
  },
};
</script>

<style scoped>


/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
