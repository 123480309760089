<template>
    <v-card
      elevation="5"
      style="padding: 30px; height: 830px;"
    >
      <v-row>
        <v-select
          label="CPU"
          v-model="selectItemCPUnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="쿨러"
          v-model="selectItemCPUcoolernm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="메인보드"
          v-model="selectItemMainBoardnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="메모리"
          v-model="selectItemMemorynm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="SSD"
          v-model="selectItemSSDnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="HDD"
          v-model="selectItemHDDnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="그래픽카드"
          v-model="selectItemVGAnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="파워서플라이"
          v-model="selectItemPSUnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="케이스"
          v-model="selectItemCASEnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          label="운영체제"
          v-model="selectItemOSnm"
          variant="outlined"
          readonly
        ></v-select>
      </v-row>
      <v-spacer></v-spacer>

      
      <v-row>
          <span>
              <span style="font-size: 22px; font-weight: 800;">{{ formatPrice(selectPCSaleprice) }}</span>원
          </span>
          <v-spacer></v-spacer>
            <v-btn variant="outlined"
              style="
              border-color: #C4C4C4;
              color: black;
              "
              @click="open_cust_info_input_dialog"
            >
              견적서출력
            </v-btn>



            
          <v-btn
            variant="outlined"
            style="
              border-color: #C4C4C4;
              color: black;
            "
            @click="goToStore"
          >
              구매하기
          </v-btn>
      </v-row>
    </v-card>


    <v-dialog
        width="800px"
        v-model="cust_info_input_dialog"
    >
        <v-card
        prepend-icon="mdi-account"
        title="고객정보"
        >
        <v-card-text>
            <v-row dense>
            <v-col cols="12" md="12" sm="6">
                <v-text-field
                v-model="insertCustNm"
                label="성함*"
                solo-inverted
                hide-details
                clearable
                >
                </v-text-field>
            </v-col>
            </v-row>
            <v-row dense>
            <v-col cols="12" md="4" sm="6">
                <v-select
                label="전화번호*"
                v-model="insertCustNumberinput1"
                :items="NumberAreaCode"
                ></v-select>
            </v-col>
            <v-col cols="12" md="4" sm="6">
                <v-text-field
                v-model="insertCustNumberinput2"
                solo-inverted
                hide-details
                clearable
                @input="limitInput"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6">
                <v-text-field
                v-model="insertCustNumberinput3"
                solo-inverted
                hide-details
                clearable
                @input="limitInput"
                >
                </v-text-field>
            </v-col>
            </v-row>
            <v-row dense>
            <v-col cols="12" md="12" sm="6">
                <v-text-field
                v-model="insertQTY"
                label="견적수량"
                solo-inverted
                hide-details
                clearable
                >
                </v-text-field>
            </v-col>
            </v-row>
        </v-card-text>

        <v-card-text tyle="display: flex; justify-content: flex-end;">
          <v-container fluid>
            <v-checkbox v-model="contactCheck">
              <template v-slot:label>
                <div>
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span
                        v-bind="props"
                        @click="policy_personal_informationToggle"
                        style="cursor: pointer; text-decoration: underline; color: blue;"
                      >
                        개인정보 수집 및 이용
                      </span>
                    </template>
                    개인정보취급방침
                  </v-tooltip>
                  에 동의합니다
                </div>
              </template>
            </v-checkbox>
          </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="orange"
            @click="open_estimate_dialog"
            style="
            font-weight: 900;
            "
        >견적서 미리보기</v-btn>

        </v-card-actions>

        </v-card>
    </v-dialog>
  <v-dialog
        width="max-content" height="max-content"
        v-model="estimate_dialog"
  >
    <EstimateManagementPrint 
      :selectedRowData="{
        insertCustNm: insertCustNm,
        insertCustNumber: insertCustNumberinput1+'-'+insertCustNumberinput2+'-'+insertCustNumberinput3,
        cartItems: cartItems
      }" />
  </v-dialog>
  <v-dialog
    width="max-content" height="max-content"
    v-model="policy_personal_information"
  >
    <v-card>
      <PersonalInformation />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="surface-variant"
          text="닫기"
          variant="flat"
          @click="policy_personal_informationToggle"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    
    <v-snackbar
      v-model="snackbarOn"
      :timeout="500"
      :color="snackbarMessageColor"
      rounded="pill"
    >
      {{ snackbarMessage }}
    </v-snackbar>

  </template>
  
  <script>
  import { ref, onMounted, watch } from 'vue';
  import { useStore } from 'vuex'; // Vuex 스토어 사용
  import { fetchGetData } from '../../common/JSfunction/usedFunction.js';
  import EstimateManagementPrint from '@/components/common/templete/PrintFormatEsti.vue';
  import PersonalInformation from '@/components/common/policy/personal_information.vue';
  
  export default {
    components: {
      EstimateManagementPrint,
      PersonalInformation,
    },
    setup() {
      const store = useStore(); // Vuex 스토어 인스턴스 가져오기
      const itemsDetail = ref([]);
      const cartItems = ref([]);
  
      const selectItemCPU = ref([]);
      const selectItemCPUcooler = ref([]);
      const selectItemMainBoard = ref([]);
      const selectItemMemory = ref([]);
      const selectItemHDD = ref([]);
      const selectItemSSD = ref([]);
      const selectItemVGA = ref([]);
      const selectItemPSU = ref([]);
      const selectItemCASE = ref([]);
      const selectItemOS = ref([]);

      const selectItemCPUnm = ref('');
      const selectItemCPUcoolernm = ref('');
      const selectItemMainBoardnm = ref('');
      const selectItemMemorynm = ref('');
      const selectItemHDDnm = ref('');
      const selectItemSSDnm = ref('');
      const selectItemVGAnm = ref('');
      const selectItemPSUnm = ref('');
      const selectItemCASEnm = ref('');
      const selectItemOSnm = ref('');
      const selectStoreParams = ref('');
      const selectPCSaleprice = ref(0);
      const selectPCname = ref('');
  
      const fetchData = async (pc_no) => {
        itemsDetail.value = [];

        try {
          const apiPath = '/menu/item/ItemRecommendPC/detail/manage/select';
          const data = await fetchGetData(apiPath, `pc_no=` + pc_no);
          itemsDetail.value = data.map(item => ({
            ...item,
            item_category: item.item_category,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          // 초기화
          selectItemCPU.value = [];
          selectItemCPUcooler.value = [];
          selectItemMainBoard.value = [];
          selectItemMemory.value = [];
          selectItemHDD.value = [];
          selectItemSSD.value = [];
          selectItemVGA.value = [];
          selectItemPSU.value = [];
          selectItemCASE.value = [];
          selectItemOS.value = [];

          selectItemCPUnm.value = '';
          selectItemCPUcoolernm.value = '';
          selectItemMainBoardnm.value = '';
          selectItemMemorynm.value = '';
          selectItemHDDnm.value = '';
          selectItemSSDnm.value = '';
          selectItemVGAnm.value = '';
          selectItemPSUnm.value = '';
          selectItemCASEnm.value = '';
          selectItemOSnm.value = '';
  
          // 각 item_category 값을 검사하여 해당 배열에 추가
          itemsDetail.value.forEach(item => {

            if (item.sale_item_category === 'CPU') {
              selectItemCPU.value.push(item);
              selectItemCPUnm.value = selectItemCPU.value[0].item_nm;
            } else if (item.sale_item_category === 'CPU쿨러') {
              selectItemCPUcooler.value.push(item);
              selectItemCPUcoolernm.value = selectItemCPUcooler.value[0].item_nm;
            } else if (item.sale_item_category === '메인보드') {
              selectItemMainBoard.value.push(item);
              selectItemMainBoardnm.value = selectItemMainBoard.value[0].item_nm;
            } else if (item.sale_item_category === '메모리') {
              selectItemMemory.value.push(item);
              selectItemMemorynm.value = selectItemMemory.value[0].item_nm + selectItemMemory.value[0].item_qty+'EA';
            } else if (item.sale_item_category === 'HDD') {
              selectItemHDD.value.push(item);
              selectItemHDDnm.value = selectItemHDD.value[0].item_nm;
            } else if (item.sale_item_category === 'SSD') {
              selectItemSSD.value.push(item);
              selectItemSSDnm.value = selectItemSSD.value[0].item_nm;
            } else if (item.sale_item_category === '그래픽카드') {
              selectItemVGA.value.push(item);
              selectItemVGAnm.value = selectItemVGA.value[0].item_nm;
            } else if (item.sale_item_category === '파워') {
              selectItemPSU.value.push(item);
              selectItemPSUnm.value = selectItemPSU.value[0].item_nm;
            } else if (item.sale_item_category === '케이스') {
              selectItemCASE.value.push(item);
              selectItemCASEnm.value = selectItemCASE.value[0].item_nm;
            } else if (item.sale_item_category === '소프트웨어') {
              selectItemOS.value.push(item);
              selectItemOSnm.value = selectItemOS.value[0].item_nm;
            } 
          });

          selectStoreParams.value = itemsDetail.value[0].smartstore_item_cd || '';
          selectPCSaleprice.value = itemsDetail.value[0].info_sale_price || 0;
          selectPCname.value = itemsDetail.value[0].pc_nm || '';

        }
      };

      const goToStore = () => {
        const url = `https://smartstore.naver.com/blackitem/products/${selectStoreParams.value}`;
        window.open(url, '_blank');
      }

      const cust_info_input_dialog = ref(false);
      const open_cust_info_input_dialog = async () => {
          cust_info_input_dialog.value = true;
      };

      const insertCustNm = ref('');
      const insertCustNumberinput1 = ref('010');
      const insertCustNumberinput2 = ref('');
      const insertCustNumberinput3 = ref('');
      const NumberAreaCode = ref(['010','011','016','017','018','019','02','031','032','033','041','042','043','044','051','052','053','054','055','061','062','063','064','0130','070','080','0506','0505','0507','0504','0503','0502','0303','050','0508']);
      const contactCheck = ref(false);
      const insertQTY = ref(1);

      const snackbarOn = ref(false);
      const snackbarMessage = ref(null);
      const snackbarMessageColor = ref(null);
      const estimate_dialog = ref(false);

      const open_estimate_dialog = async () => {
        if (!contactCheck.value) {
          snackbarOn.value = true;
          snackbarMessage.value = '개인정보 수집에 동의해주세요';
          snackbarMessageColor.value = 'red';
        } else {
          // cust_info_input_dialog.value = false;
          // estimate_dialog.value = true;
          itemCartConvert();
        }
      };

      const itemCartConvert = () => {
        cartItems.value = [];
        //견적서 상단 조립PC 항목넣기
        const titleCartItem = {
          item_category: '조립PC',
          item_nm: selectPCname.value,
          sale_price: selectPCSaleprice.value,
          cart_qty: insertQTY.value,
          sum_price: selectPCSaleprice.value * insertQTY.value,
        }

        cartItems.value.push(titleCartItem);
        
        itemsDetail.value.forEach(item => {
          // sale_item_category, item_nm, item_qty 값을 추출하여 cartItem 객체 생성
          const cartItem = {
            item_category: item.sale_item_category,
            item_nm: item.item_nm,
            sale_price: 0,
            cart_qty: item.item_qty * insertQTY.value,
            sum_price: 0,
          };

          // cartItem을 cartItems 배열에 추가
          cartItems.value.push(cartItem);
        });

        if(selectItemOS.value.length === 0) {
          const titleCartItem = {
            item_category: '운영체제',
            item_nm: 'Free Dos / 운영체제포함되지않음',
            sale_price: 0,
            cart_qty: insertQTY.value,
            sum_price: 0,
          }
          cartItems.value.push(titleCartItem);
        }

        //구매링크 삽입
        const buyLinkCartItem = {
          item_category: '구매링크',
          item_nm: 'https://smartstore.naver.com/blackitem/products/'+selectStoreParams.value,
          sale_price: 0,
          cart_qty: 1,
          sum_price: 0,
        }

        cartItems.value.push(buyLinkCartItem);
      
        cust_info_input_dialog.value = false;
        estimate_dialog.value = true;
      };



      const formatPrice = (price) => {
      // price를 숫자로 파싱한 후, toLocaleString을 사용하여 한국 원 단위 포맷으로 변경
      const formattedPrice = Number(price).toLocaleString('ko-KR');
      return `${formattedPrice}`; // '원' 문자열을 추가하여 반환
      };

      const policy_personal_information = ref(false);
      const policy_personal_informationToggle = () => {
        policy_personal_information.value = !policy_personal_information.value
      };
  
      onMounted(() => {
        const currentPcNo = store.getters.getPcNo;
        if (currentPcNo !== null && currentPcNo !== undefined) {
            fetchData(currentPcNo); // 컴포넌트가 마운트될 때 현재 pc_no로 fetchData 호출
        }
      });
  
      // watch 사용하여 store의 getPcNo getter를 감시
      watch(
        () => store.getters.getPcNo, // 감시할 getter
        (newPcNo) => {
          // getter의 값이 변경될 때 실행되는 콜백 함수
          if (newPcNo !== null && newPcNo !== undefined) {
            fetchData(newPcNo); // fetchData 호출
          }
        },
        { immediate: true } // 현재 값도 즉시 호출
      );
  
      return {
        itemsDetail,
        selectItemCPU, selectItemCPUcooler, selectItemMainBoard, selectItemMemory, selectItemHDD, selectItemSSD,
        selectItemVGA, selectItemPSU, selectItemCASE, selectItemOS,
        selectItemCPUnm, selectItemCPUcoolernm, selectItemMainBoardnm, selectItemMemorynm, selectItemHDDnm,
        selectItemSSDnm, selectItemVGAnm, selectItemPSUnm, selectItemCASEnm, selectItemOSnm,
        fetchData, selectStoreParams, selectPCSaleprice, selectPCname, policy_personal_information, policy_personal_informationToggle,
        goToStore, formatPrice, open_cust_info_input_dialog, cust_info_input_dialog,
        insertCustNm, insertCustNumberinput1, insertCustNumberinput2, insertCustNumberinput3, NumberAreaCode, contactCheck,
        open_estimate_dialog, snackbarOn, snackbarMessage, snackbarMessageColor, estimate_dialog,
        cartItems, itemCartConvert, insertQTY,
      };
    }
  };
  </script>
  