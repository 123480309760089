<template>
    <v-footer
      v-if="isMobile === true"
      class="text-center d-flex flex-column"
      style="
      background-color: #212121;
      color: white;
      font-size: 13px;
      "
    >
      <v-divider></v-divider>
      <br>
      <img src="https://cdn.samjinnetworks.com/images/web/main_logo_white.png">
      <br>
        <br>
        사업자번호 : 285-16-00432<br>
        대표자 : 박민수<br>
        경기도 수원시 영통구 센트럴타운로 107 (광교푸르지오 월드마크, 월드스퀘어) 204동 1층 1-047호<br>
        고객센터 : 0507-1374-6229<br><br>
      <div>
        Copyright {{ new Date().getFullYear() }}©
        <strong> Samjin Networks </strong>
        Co., Ltd. All Rights Reserved.
      </div>
    </v-footer>

    <v-footer
      v-if="isMobile === false"
      class="text-center d-flex flex-column"
      style="
      background-color: #212121;
      color: white;
      font-size: 13px;
      "
    >
      <v-container
        style="text-align: left; margin: 35px;"
      >
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <img src="https://cdn.samjinnetworks.com/images/web/main_logo_white.png"><br>
            삼진네트웍스<br>
            경기도 수원시 영통구 센트럴타운로 107 (광교푸르지오 월드마크, 월드스퀘어) 204동 1층 1-047호<br>
            고객센터 : 0507-1374-6229<br>
            이메일주소 : info@samjinnetworks.com<br>
            <br>
            대표자 박민수 | 사업자등록번호 285-16-00432 | 통신판매업신고번호 :제 2020-수원영통-1164
            
          </v-col>

          <v-col cols="12" md="2" sm="6">

            개인정보취급방침<br>
            이용약관
          </v-col>
          <v-col cols="12" md="2" sm="6">
            <h3>소프트웨어</h3><br>
            <div @click="handleMenuItemClick('ubuntu')" style="cursor: pointer;">
              호스팅서비스 - LinuxUbuntu
            </div>
            <div @click="handleMenuItemClick('nextcloud')" style="cursor: pointer;">
              호스팅서비스 - Nextcloud
            </div>
          </v-col>
          <v-col cols="12" md="2" sm="6">
            <h3>하드웨어</h3><br>

            <div @click="handleMenuItemClick('온라인견적서')" style="cursor: pointer;">
              조립컴퓨터 온라인견적
            </div>
            <div @click="handleMenuItemClick('추천조립PC')" style="cursor: pointer;">
              조립컴퓨터 추천조립PC
            </div>
            <div @click="handleMenuItemClick('기업용렌탈')" style="cursor: pointer;">
              기업렌탈 서비스
            </div>
            <div @click="handleMenuItemClick('중고매입신청')" style="cursor: pointer;">
              중고매입 서비스
            </div>
            

          </v-col>

          <v-divider></v-divider>
          <div>
            Copyright {{ new Date().getFullYear() }}©
            <strong> Samjin Networks </strong>
            Co., Ltd. All Rights Reserved.
          </div>
        </v-row>
      </v-container>

    </v-footer>
  </template>
<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const isMobile = ref(false);
    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    const handleMenuItemClick = (item) => {
      if (item.title || item === 'Contact Us') {
        router.push('/contact');
      } else if (item.title || item === '온라인견적서') {
        router.push('/estimate');
      } else if (item.title || item === '추천조립PC') {
        router.push('/recommend');
      } else if (item.title || item === '기업용렌탈') {
        router.push('/rental');
      } else if (item.title || item === '중고매입신청') {
        router.push('/purchase');
      } else if (item.title || item === '원격지원') {
        router.push('/remotesupport');
      } else if (item.title || item === 'nextcloud') {
        router.push('/nextcloud');
      } else if (item.title || item === 'ubuntu') {
        router.push('/ubuntu');
      }
    };

    onMounted( async () => {
      checkIsMobile();
    });

    return {
      isMobile,
      checkIsMobile, handleMenuItemClick,
    }
  }
}
</script>