<template>
  <div class="container">
    <v-card
      class="card"
      :style="{
        width: isMobile ? '100%' : '100%',
        padding: isMobile ? '10px' : '0px',
        height: isMobile ? '200px' : '300px',
        
        marginTop: isMobile ? '5px' : '0px',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
      }"
      style="
      background-color: #0082C9;
      "
      elevation="0"
    >
    
      <!-- Text section (50% width) -->
      <div class="image-section" style="justify-content: center; text-align: center;" :style="{
        paddingTop: isMobile ? '10px' : '35px',
      }">

      <v-container
        style="text-align: left; width: 1200px;"
      >
        <div
          :style="{
            fontSize: isMobile ? '20px' : '35px',
            fontWeight: isMobile ? '900' : '600',
            color: 'white',
          }">
            설치/관리/유지보수 모두 신경쓰지마세요
            <br>전문가가 직접설치부터 백업까지
          </div>
          <br>
          <div
            :style="{
              fontSize: isMobile ? '15px' : '17px',
              fontWeight: isMobile ? '100' : '500',
              color: 'white',
            }"
          >
            문서 중앙화 더 이상 고민하지마시고 업무에만 집중하세요
            <br>정기적인 백업과 업데이트를 지원합니다
          </div>
      </v-container>

      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
  methods: {
    redirectToContact() {
      this.$router.push('/contact');
    }
  },
  setup() {
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value)
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}



/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
