import axios from 'axios';

export const fetchGetData = async (apiPath, params = '') => {
  try {
    const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
    const apiKey = process.env.VUE_APP_VERIFY_KEY;
    const uuid = process.env.VUE_APP_VERIFY_UUID;
    let url = `${request}${apiPath}?apiKey=${apiKey}&uuid=${uuid}`;
    
    if (params) {
      url += `&${params}`;
    }
    
    const response = await axios.get(url);

    // 만약 selectData가 없거나 selectData 외에 다른 데이터가 존재한다면
    if (!response.data.selectData || Object.keys(response.data).length > 1) {
      // 모든 데이터를 하나의 객체로 묶어서 반환
      return {
        ...response.data
      };
    } else {
      // 그렇지 않다면 단일 selectData를 반환
      return response.data.selectData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // 에러를 다시 throw하여 호출한 쪽에서 catch할 수 있도록 함
  }
};


export const fetchPostData = async (apiPath, data) => {
  const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
  let url = `${request}/${apiPath}`;
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error('등록실패:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const formatPrice = (price) => {
    // price를 숫자로 파싱한 후, toLocaleString을 사용하여 한국 원 단위 포맷으로 변경
    const formattedPrice = Number(price).toLocaleString('ko-KR');
    return `${formattedPrice}원`;
};

export const formatDate = (date) => {
  const newDate = new Date(date);
  const formaatedDate = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`;
  return `${formaatedDate}`;
};

export const FunctionExportCSV = (items, menuName, headers, filterHeaders) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  // 현재 날짜를 YYYY-MM-DD 형식으로 변환
  const today = `${year}-${month}-${day}_${hours}-${minutes}`;

  // 파일명 생성: "메뉴이름_YYYY-MM-DD.csv"
  const fileName = `${menuName}_${today}.csv`;

  // UTF-8 BOM 추가
  let csvContent = '\uFEFF';

  // CSV 헤더 추가
  const filteredHeaders = headers.filter(h => !filterHeaders.includes(h.value));
  const headersRow = filteredHeaders.map(h => h.title).join(',');
  csvContent += headersRow + '\r\n';

  // 데이터 행 추가
  items.forEach(item => {
    const row = filteredHeaders.map(header => `"${item[header.value]}"`).join(',');
    csvContent += row + '\r\n';
  });

  // CSV 데이터를 Blob 객체로 변환 및 다운로드 링크 생성
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyToClipboard = async (copyText) => {
  try {
    await navigator.clipboard.writeText(copyText);
  } catch (error) {
    console.error('상품명 복사 실패:', error);
  }
};

export const openDetailImage = (itemname, itemdetail, itemimagelink, itemdetailimagelink) => {
  const itemitemimagelinkHtml = itemimagelink ? `<td><img width="350px" src="${itemimagelink}"></td>` : '';
  const itemNameHtml = itemname ? `<div class="split">${itemname}</div><br>` : '';
  const itemDetailHtml = itemdetail ? `<div class="split">짧은 설명</div><br> <div class="split">${itemdetail}</div>` : '';

  const htmlContent = `
    <html>
      <head>
        <title>상세정보</title>
      </head>
      <body style="display: flex; justify-content: center; align-items: center;">
        <center>
          <table>
            <tr>
              ${itemitemimagelinkHtml}
              <td rowspan="3">
                ${itemNameHtml}
                ${itemDetailHtml}
              </td>
            </tr>
          </table>
          ${itemdetailimagelink}
        </center>
      </body>
    </html>
  `;

  const imageWindow = window.open('', 'Image Preview', 'width=1000,height=2000');
  imageWindow.document.write(htmlContent);
  imageWindow.document.close();
};
