<template>
  <div class="container">
    <v-card
      class="card"
      :style="{
        width: isMobile ? '100%' : '70%',
        padding: isMobile ? '10px' : '0px',
        height: isMobile ? '700px' : '460px',
        borderRadius: isMobile ? '0px' : '0px',
        marginTop: isMobile ? '0px' : '0px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
      }"
      style="
      background-color: #FFFFFF;
      "
      elevation="0"
    >

    <div class="text-section" style="flex: 1;">
        <div
        :style="{
          fontSize: isMobile ? '30px' : '22px',
          fontWeight: isMobile ? '900' : '900',
        }">
          모든 파일을 중앙에 저장한 통합관리 시스템
        </div>

          <div
            :style="{
              fontSize: isMobile ? '15px' : '15px',
              fontWeight: isMobile ? '100' : '500',
              paddingTop: '20px',
            }"
          >
            시스템에 관계없이 모든 자료를 서버에 저장하여 통합관리를 함으로써 파편화 되어있는 파일들을 중복제거하여 최신자료의 유지가 가능하며 권한이나 손쉬운 공유기능으로 물리적인 USB/외장하드없이 자유롭게 공유가 가능합니다
          </div><br>
          <div
            :style="{
              fontSize: isMobile ? '14px' : '15px',
              fontWeight: isMobile ? '100' : '500',
            }"
          >
            지금바로 상담받아보세요!
          </div>
          
      </div>
      <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
        <img src="/images/nextcloud/nextcloud-bottom-image.png" :width="isMobile ? '700' : '600'">
      </div>
      

    </v-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed, onMounted } from 'vue';

export default {
  setup() {
    const router = useRouter();
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    const goToestimate = () => {
      router.push('/estimate');
    }

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      goToestimate,
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value),
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}



/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 0px 20px 0 20px;
  }
}
</style>
