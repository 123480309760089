<template>
  <div class="container">
    <v-card
      class="card"
      :style="{
        width: isMobile ? '100%' : '80%',
        padding: isMobile ? '10px' : '0px',
        height: isMobile ? '700px' : '500px',
        borderRadius: '40px',
        marginTop: isMobile ? '20px' : '40px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
      }"
      style="
      background-color: #F4F4F7;
      "
      elevation="0"
    >
      <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
        <img src="https://cdn.samjinnetworks.com/images/web/banner/banner1.png" :width="isMobile ? '320' : '360'">
      </div>

      <!-- Text section (50% width) -->
      <div class="text-section" style="flex: 1;">
        <div
        :style="{
          fontSize: isMobile ? '30px' : '55px',
          fontWeight: isMobile ? '900' : '900',
        }">
          온라인 조립PC 견적
        </div>

          <div
            :style="{
              fontSize: isMobile ? '15px' : '27px',
              fontWeight: isMobile ? '100' : '500',
            }"
          >
            원하는 사양이나 예산에 맞춰 컴퓨터를 구성하세요
          </div><br>
          <div
            :style="{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: isMobile ? '100' : '300',
            }"
          >
            원하는 사양에 맞춰 견적을 작성 해보세요<br>
            당사는 귀하의 요구 사항을 정확히 파악하고, 이를 바탕으로 최적의 시스템을 구성해드립니다
          </div>
          
          <div class="image-section" :style="{ flex: '1', display: 'flex', justifyContent: isMobile ? 'center' : 'left' }">
            <v-btn variant="text" style="background-color: #312D2A; margin-top: 10px;" color="white" @click="goToestimate">+ More</v-btn>
          </div>
          
          


      </div>
    </v-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed, onMounted } from 'vue';

export default {
  setup() {
    const router = useRouter();
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    const goToestimate = () => {
      router.push('/estimate');
    }

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      goToestimate,
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value),
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}



/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
