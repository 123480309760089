<template>
  <div class="container">
    <v-card
      v-if="!isMobile"
      class="card"
      :style="{
        width: isMobile ? '100%' : '80%',
        height: isMobile ? '50px' : '500px',
        marginTop: isMobile ? '5px' : '5px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
      }"
      style="
      background-color: white;
      
      "
      elevation="0"
    >

    <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
        <v-carousel
          show-arrows="hover"
          hide-delimiters
          :style=" {
            padding: isMobile ? '0px' : '40px 40px 40px 0px',
          }
          "
        >
          <v-carousel-item style="width: 100%; border-radius: 40px;">
            <v-img
              aspect-ratio="4/3"
              cover
              src="https://cdn.samjinnetworks.com/images/web/banner/banner2_1.jpg"
              style="
                align-items: end;
                text-align: center;
                color: white;
                font-size: 35px;
                font-weight: 900;
                border-radius: 40px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
              "
            >GEFORCE RTX 40 SERIES</v-img>
          </v-carousel-item>
          <v-carousel-item style="width: 100%; border-radius: 40px;">
            <v-img
              aspect-ratio="4/3"
              cover
              src="https://cdn.samjinnetworks.com/images/web/banner/banner2_2.jpg"
              style="
                align-items: end;
                text-align: center;
                color: white;
                font-size: 35px;
                font-weight: 900;
                border-radius: 40px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
              "
            >GRAPHICS CARD</v-img>
          </v-carousel-item>
          <v-carousel-item style="width: 100%; border-radius: 40px;">
            <v-img
              aspect-ratio="4/3"
              cover
              src="https://cdn.samjinnetworks.com/images/web/banner/banner2_3.jpg"
              style="
                align-items: end;
                text-align: center;
                color: white;
                font-size: 35px;
                font-weight: 900;
                border-radius: 40px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
              "
            >GEFORCE RTX 40 SERIES</v-img>
          </v-carousel-item>
        </v-carousel>
    </div>

    <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
      <v-carousel
          show-arrows="hover"
          hide-delimiters
          :style=" {
            padding: isMobile ? '0px' : '40px 0px 40px 0px',
          }
          "
        >
          <v-carousel-item style="width: 100%; border-radius: 40px;">
            <v-img
              aspect-ratio="4/3"
              cover
              src="https://cdn.samjinnetworks.com/images/web/banner/banner2_2_1.jpg"
              style="
                align-items: top;
                text-align: center;
                color: white;
                font-size: 42px;
                font-weight: 900;
                border-radius: 40px;
                text-shadow: 3px 3px 15px rgba(0, 0, 0, 0.8);
              "
            ><br>BETTER USER EXPERIENCE</v-img>
          </v-carousel-item>
          <v-carousel-item style="width: 100%; border-radius: 40px;">
            <v-img
              aspect-ratio="4/3"
              cover
              src="https://cdn.samjinnetworks.com/images/web/banner/banner2_2_2.jpg"
              style="
                align-items: center;
                text-align: center;
                color: white;
                font-size: 35px;
                font-weight: 900;
                border-radius: 40px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
              "
            >AMD Ryzen Series</v-img>
          </v-carousel-item>
        </v-carousel>
    </div>

    <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
      <v-carousel
          show-arrows="hover"
          hide-delimiters
          :style=" {
            padding: isMobile ? '0px' : '40px 0px 40px 40px',
          }
          "
        >
          <v-carousel-item style="width: 100%; border-radius: 40px;">
            <v-img
              aspect-ratio="4/3"
              cover
              src="https://cdn.samjinnetworks.com/images/web/banner/banner2_3_1.jpg"
              style="
                align-items: end;
                text-align: center;
                color: white;
                font-size: 35px;
                font-weight: 900;
                border-radius: 40px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
              "
            >AMD RYZEN 7000 SERIES</v-img>
          </v-carousel-item>
          <v-carousel-item style="width: 100%; border-radius: 40px;">
            <v-img
              aspect-ratio="4/3"
              cover
              src="https://cdn.samjinnetworks.com/images/web/banner/banner2_3_2.jpg"
              style="
                align-items: end;
                text-align: center;
                color: white;
                font-size: 35px;
                font-weight: 900;
                border-radius: 40px;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
              "
            >A HIGH-PERFORMANCE GAMING COMPUTER</v-img>
          </v-carousel-item>
        </v-carousel>
    </div>




      

    </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
  setup() {
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value)
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}


/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
