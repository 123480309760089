<template>
    <div class="container">
      <v-card
        class="card"
        :style="{
          width: isMobile ? '100%' : '70%',
          padding: isMobile ? '10px' : '0px',
          height: isMobile ? '700px' : '460px',
          borderRadius: isMobile ? '0px' : '0px',
          marginTop: isMobile ? '0px' : '0px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center'
        }"
        style="
        background-color: #FFFFFF;
        "
        elevation="0"
      >


        <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <img src="/images/nextcloud/nextcloud-groupware-preview.png" :width="isMobile ? '700' : '700'">
        </div>
        <div class="text-section" style="flex: 1; padding-left: 30px;">
          <div
          :style="{
            fontSize: isMobile ? '30px' : '22px',
            fontWeight: isMobile ? '900' : '900',
          }">
            넥스트클라우드 그룹웨어
          </div>
  
            <div
              :style="{
                fontSize: isMobile ? '15px' : '15px',
                fontWeight: isMobile ? '100' : '500',
                paddingTop: '20px',
              }"
            >
              Nextcloud Groupware는 팀이 더 빠르고 쉽게 작업을 완료할 수 있도록 캘린더, 연락처, 메일 등 다양한 생산성 기능을 통합하여 사용자 요구에 맞게 제공합니다.
            </div><br>
            <div
              :style="{
                fontSize: isMobile ? '14px' : '15px',
                fontWeight: isMobile ? '100' : '500',
              }"
            >
              캘린더, 연락처, 메일 및 기타 생산성 기능을 통합하여 팀이 더 빠르고 쉽게 작업을 완료할 수 있도록 지원하며, 사용자의 요구에 맞게 제공합니다.
            </div>
            
        </div>
  
      </v-card>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  import { ref, computed, onMounted } from 'vue';
  
  export default {
    setup() {
      const router = useRouter();
      const isMobile = ref(false);
  
      const checkIsMobile = () => {
        isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
      };
  
      const goToestimate = () => {
        router.push('/estimate');
      }
  
      onMounted(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
      });
  
      return {
        goToestimate,
        isMobile: computed(() => isMobile.value),
        isDesktop: computed(() => !isMobile.value),
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
  }
  
  
  
  /* Styles for mobile */
  @media screen and (max-width: 768px) {
    .text-section {
      padding: 0px 20px 0 20px;
    }
  }
  </style>
  