<template>
  <div class="container">
    <v-card
      class="card"
      :style="{
        width: isMobile ? '100%' : '100%',
        padding: isMobile ? '10px' : '0px',
        height: isMobile ? '380px' : '500px',
        
        marginTop: isMobile ? '5px' : '0px',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
      }"
      style="
      background-color: #F4F4F7;
      "
      elevation="0"
    >
    
      <!-- Text section (50% width) -->
      <div class="image-section" style="justify-content: center; text-align: center;" :style="{
        paddingTop: isMobile ? '20px' : '100px',
      }">
        <div
        :style="{
          fontSize: isMobile ? '30px' : '55px',
          fontWeight: isMobile ? '900' : '900',
        }">
          맞춤형 조립PC 주문제작
        </div>

          <div
            :style="{
              fontSize: isMobile ? '15px' : '27px',
              fontWeight: isMobile ? '100' : '500',
            }"
          >
            모든 시스템은 구매자의 요청에 따른 충분한 상담을 통해 제작됩니다
          </div><br>
          <div
            :style="{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: isMobile ? '100' : '300',
            }"
          >
            시스템구성을 위한 장치의 사용용도를 명확히 파악하고 예산을 적절하게 분배하여<br>
            낭비되거나 부족함이 없는 시스템 구성의 제공을 목표로 모든 측면에서 예산에<br>
            가장 적합한 구성을 선택하여 이를 바탕으로 최적화 된 시스템의 최고의 성능과 품질을 경험해 보세요.
          </div>
          
          <div class="image-section" :style="{ flex: '1', display: 'flex', justifyContent: isMobile ? 'center' : 'center' }">
            <v-btn variant="text" style="background-color: #312D2A; margin-top: 10px;" color="white" @click="redirectToContact">Contact Us</v-btn>
          </div>
          
          


      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
  methods: {
    redirectToContact() {
      this.$router.push('/contact');
    }
  },
  setup() {
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value)
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}



/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
