<template>
    <div>
      <!-- 상단 배너 -->
      <div :style="{ backgroundColor: componentStyles.HeaderBackgroundColor, height: '65px', marginTop: '-65px' }"></div>
      
      <!-- Contact Us 섹션 -->
      <div
        v-if="!isMobile"
          style="
          height: 40px;
          margin-top: 0px;
          color: #ffffff;
          display: flex;
          align-items: center;
          padding-left: 30px;
          padding-right: 15px;
        "
        :style="{ backgroundColor: componentStyles.HeaderSubBackgroundColor }"
        >
        {{ componentStyles.TitleName }}
        <p v-if="userPermission === '0'" style="padding-left: 5px;">관리자모드</p>
        <v-spacer></v-spacer>
        <div v-if="!userPermission" class="text-center">
          <v-btn
            prepend-icon="mdi-mail"
            style="
              color: #ffffff;
            "
            :style="{
              backgroundColor: componentStyles.HeaderSubBackgroundColor
            }"
            @click="sendEmail"
          >
            <template v-slot:prepend>
              <v-icon color="#ffffff"></v-icon>
            </template>
            info@samjinnetworks.com
          </v-btn>
  
          <a
            v-if="isMobile"
            :href="'tel:' + phoneNumber"
            style="
              text-decoration: none;
              color: #ffffff;
              background-color: #262626;
              padding: 0 15px;
              display: inline-block;
            "
          >
            <v-icon color="#ffffff" left>mdi-phone</v-icon>
            {{ phoneNumber }}
          </a>
          <v-btn
            v-else
            prepend-icon="mdi-phone"
            style="
              background-color: #262626;
              color: #ffffff;
            "
            :style="{
              backgroundColor: componentStyles.HeaderSubBackgroundColor
            }"
            @click="copyPhoneNumber"
          >
            <template v-slot:prepend>
              <v-icon color="#ffffff"></v-icon>
            </template>
            {{ phoneNumber }}
          </v-btn>
        </div>
        <div v-if="userPermission" class="text-center">
          <v-btn
            prepend-icon="mdi-logout"
            style="
              background-color: #262626;
              color: #ffffff;
            "
            @click="logout"
          >
            <template v-slot:prepend>
              <v-icon color="#ffffff"></v-icon>
            </template>
            로그아웃
          </v-btn>
        </div>
      </div>
    </div>
  </template>
  
<script>
import { ref, computed, onMounted } from 'vue';
  
export default {
  props: {
        componentStyles: {
            type: Object,
            default: () => ({
                HeaderBackgroundColor: '#06384F',
                HeaderSubBackgroundColor: '#262626',
                TitleName: '',
            }),
        },
        userPermission: {
            type: String,
            default: null,
        },
    },
    setup() {
      const isMobile = ref(false);
      const phoneNumber = ref('0507-1374-6229');
  
      const checkIsMobile = () => {
        isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
      };
  
      const sendEmail = () => {
        window.location.href = 'mailto:info@samjinnetworks.com';
      };
  
      const logout = () => {
        localStorage.removeItem('vft_uuid_iv');
        localStorage.removeItem('uuid_iv');
        window.location.reload();
      };
  
      const copyPhoneNumber = () => {
        navigator.clipboard.writeText(phoneNumber.value).then(() => {
          alert('전화번호가 복사되었습니다.');
          console.log(phoneNumber.value);
        }).catch((err) => {
          console.error('복사 실패: ', err);
        });
      };
  
      onMounted(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
      });
  
      return {
        isMobile: computed(() => isMobile.value),
        isDesktop: computed(() => !isMobile.value),
        phoneNumber,
        logout,
        sendEmail,
        copyPhoneNumber,
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
  }
  
  /* Styles for mobile */
  @media screen and (max-width: 768px) {
    .text-section {
      padding: 20px 20px 0 20px;
    }
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem;
  }
  </style>
  