<template>
  <div class="container">
      <v-card
        class="card"
        elevation="20"
        style="
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          padding-top: 50px;
          margin-bottom: 50px;
        "
        :style="{
          width: isMobile ? '100%' : '80%',
          height: isMobile ? 'max-content' : 'max-content',
        }"
        >
          <div
          style="
            color: #06384F;
          "
          :style="{
            fontSize: isMobile ? '30px' : '55px',
            fontWeight: isMobile ? '900' : '900',
          }
          ">
            상담/문의하기
          </div>
          <div
          style="
            color: #AEAEAE;
            padding-bottom: 20px;
          "
            :style="{
              fontSize: isMobile ? '13px' : '16px',
              fontWeight: isMobile ? '200' : '300',
            }"
          >
            궁금하신 사항이 있다면 언제든지 문의해주세요.
          </div>

            <v-card
              width="100%"
              height="100%"
              elevation="0"
              style="
                justify-content: start;
                align-items: center;
                padding-bottom: 20px;
              "
              :style="{
                width: isMobile ? '100%' : '50%',
              }"
            >
              <div class="px-4 mb-2">
                <v-chip-group v-model="selection" selected-class="bg-deep-purple-lighten-2">
                  <v-text-field clearable v-model="contactName" label="성함" variant="solo-filled" placeholder="홍길동" style="width: 100%;" :class="{ 'w-100': isMobile }"></v-text-field>
                </v-chip-group>
              </div>
              <div class="px-4 mb-2">
                <v-chip-group v-model="selection" selected-class="bg-deep-purple-lighten-2">
                  <v-text-field clearable v-model="contactEmail" label="이메일" variant="solo-filled" placeholder="email@gmail.com" style="width: 100%;" :class="{ 'w-100': isMobile }"></v-text-field>
                </v-chip-group>
              </div>
              <div class="px-4 mb-2">
                <v-chip-group v-model="selection" selected-class="bg-deep-purple-lighten-2">
                  <v-text-field clearable v-model="contactNumber" label="연락처" variant="solo-filled" placeholder="010-0000-0000" style="width: 100%;" :class="{ 'w-100': isMobile }"></v-text-field>
                </v-chip-group>
              </div>
              <v-card-title style="font-weight: 900;">문의종류</v-card-title>
              <div class="px-4 mb-2">
                <v-chip-group v-model="selection" selected-class="bg-deep-purple-lighten-2">
                  <v-select
                    label=""
                    v-model="contactType"
                    :items="['제품문의','기술문의']"
                  ></v-select>
                </v-chip-group>
              </div>
              <v-card-title style="font-weight: 900;">문의내용</v-card-title>
              <div class="px-4 mb-2">
                <v-chip-group v-model="selection" selected-class="bg-deep-purple-lighten-2">
                  <v-textarea clearable @input="addNewline" v-model="contactContent" label="" variant="solo-filled" style="width: 100%; height: 100px;" :class="{ 'w-100': isMobile }"></v-textarea>
                </v-chip-group>
              </div>

                <v-checkbox v-model="contactCheck" label="개인정보 수집 및 이용에 동의합니다."></v-checkbox>

              <v-card-actions>
                <v-btn
                  color="#06384F"
                  block
                  :loading="validating"
                  border
                  @click="sendEmail"
                  style="
                  font-weight: 900;
                  color: white;
                  background-color: #06384F;
                  "
                > 보내기 </v-btn>
              </v-card-actions>
              <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
                {{ snackbarContent }}
              </v-snackbar>
            </v-card>


      </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const isMobile = ref(false);
    const contactType = ref('제품문의');
    const contactName = ref('');
    const contactEmail = ref('');
    const contactNumber = ref('');
    const contactContent = ref('');
    const snackbar = ref(false);
    const timeout = ref(3000);
    const snackbarContent = ref('');
    const snackbarColor = ref(null);
    const validating = ref(false);
    const contactCheck = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    const addNewline = (event) => {
      if (event.key === 'Enter') {
        contactContent.value += '\n';
      }
    };

    const sendEmail = async () => {
      validating.value = true;


      if (!contactName.value || !contactEmail.value || !contactNumber.value || !contactContent.value) {
        snackbarContent.value = '모든항목을 입력하세요';
        snackbarColor.value = 'info';
        snackbar.value = true;
        validating.value = false;
        return;
      }
      if (!contactCheck.value) {
        snackbarContent.value = '개인정보 수집 동의에 체크하여주세요';
        snackbarColor.value = 'info';
        snackbar.value = true;
        validating.value = false;
        return;
      }

      try {
        const contents = `
          이름: ${contactName.value}
          이메일주소: ${contactEmail.value}
          전화번호: ${contactNumber.value}
          문의종류: ${contactType.value}
          문의내용: ${contactContent.value}
        `;
        const response = await axios.post('https://api.samjinnetworks.com/api/web/www/contact/email/send', {
          contents: contents
        });
        console.log(response.data);
        
        contactType.value = '제품문의';
        contactName.value = '';
        contactEmail.value = '';
        contactNumber.value = '';
        contactContent.value = '';
        snackbarColor.value = 'success'
        snackbarContent.value = '문의내용이 정상적으로 등록되었습니다';
        snackbar.value = true;
        validating.value = false;

      } catch (error) {
        console.error(error.response.data);
        // 이메일 전송에 실패한 경우 사용자에게 알릴 수 있음
      }
    };



    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value),
      contactType, contactName, contactEmail, contactNumber, contactContent, snackbar, timeout, snackbarContent,
      snackbarColor, validating, contactCheck,
      sendEmail, addNewline,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  height: max-content;
}



/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
