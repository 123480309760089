<template>
    <banner1 />
    <banner2 />
    <banner3 />
    <banner4 />
    <banner5 />
    <banner6 />
    <banner7 />
    <banner8 />
    <banner9 />
</template>
  
<script>
import banner1 from './nextcloud_body_banner1.vue';
import banner2 from './nextcloud_body_banner2.vue';
import banner3 from './nextcloud_body_banner3.vue';
import banner4 from './nextcloud_body_banner4.vue';
import banner5 from './nextcloud_body_banner5.vue';
import banner6 from './nextcloud_body_banner6.vue';
import banner7 from './nextcloud_body_banner7.vue';
import banner8 from './nextcloud_body_banner8.vue';
import banner9 from './nextcloud_body_banner9.vue';

export default {
  components: {
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    banner6,
    banner7,
    banner8,
    banner9,
  },
  setup() {

  }
}
</script>
  