<template>
  <v-col cols="auto">
    <v-card
      class="mx-auto"
      title="미리보기"
      subtitle="견적서 출력"
    >
    <v-overlay :model-value="loading" class="align-center justify-center">
      <p style="color: white; font-size: 22px; text-align: center;">
        잠시만 기다려주세요
      </p>
      <br>
      <v-progress-circular color="primary" size="200" indeterminate></v-progress-circular>
    </v-overlay>

      <template v-slot:append>

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn class="print-button" v-bind="props">
              <v-icon left>mdi-printer</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="htmltoPDF">
              PDF다운로드
            </v-list-item>
            <v-list-item @click="htmltoImage">
              JPG다운로드
            </v-list-item>
            <v-list-item @click="printContent">
              새창으로 확대보기
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-card>
  </v-col>

<v-card style="height: 100vh; overflow-y: auto;">
<v-container class="shadow-container">
  <v-container ref="a4Container" class="a4-container" style="background-color:#fff">
    <v-container class="quote-container">
      <h2 class="text-center" style="font-size: 27px;">견 적 서</h2>
    </v-container>
    <div>
      <v-subheader class="custom-send"> 견적일시 : {{todayYYYYMMDDHHMM}} </v-subheader><br>
      <v-subheader class="custom-subheader">아래와 같이 견적서를 제출합니다.</v-subheader>
    </div>

    <div style="display: flex;">

      <div style="flex: 29;">
        <table class="table-style">
          <tbody>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="text-align: center; border-right: 0px;">견적일자</td>
              <td colspan="3" class="cell-style" style="text-align: left; padding-left: 10px; "> {{ todayYYYYMMDD }}</td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="text-align: center; border-top: 0px; border-right: 0px;">유효기간</td>
              <td colspan="3" class="cell-style" style="text-align: left; border-top: 0px; padding-left: 10px; ">1일이내</td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="text-align: center; border-top: 0px; border-right: 0px;">성&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;함</td>
              <td colspan="3" class="cell-style" style="text-align: left; border-top: 0px; padding-left: 10px; "><p> {{ selectedRowData.insertCustNm }} 고객님</p></td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="text-align: center; border-top: 0px; border-right: 0px;">전화번호</td>
              <td colspan="3" class="cell-style" style="text-align: left; border-top: 0px; padding-left: 10px; "> {{ selectedRowData.insertCustNumber }} </td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="text-align: center; border-top: 0px; border-right: 0px;">보증기간</td>
              <td colspan="3" class="cell-style" style="text-align: left; border-top: 0px; padding-left: 10px; "> 납품일 기준 1년 무상보증 </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="width: 3px;"></div>
      <div style="flex: 44;">
        <table class="table-style">
          <tbody>
            <tr>
              <td width="25" height="35" rowspan="5" class="cell-style header-cell-style" style="border-right: 0px;">공<br>급<br>자</td>
              <td height="35" class="cell-style header-cell-style" style="border-right: 0px; text-align: center;">사&nbsp;업&nbsp;자&nbsp;번&nbsp;호</td>
              <td colspan="3" class="cell-style" style="text-align: left; padding-left: 10px;">285-16-00432</td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="border-right: 0px; border-top: 0px; text-align: center;">상&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;호&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</td>
              <td class="cell-style" style="text-align: left; padding-left: 10px; border-right: 0px; border-top: 0px;">삼진네트웍스</td>
              <td class="cell-style header-cell-style" style="border-right: 0px; border-top: 0px; text-align: center;">대&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;표</td>
              <td class="cell-style image-cell-style" style="text-align: left; padding-left: 10px; border-top: 0px;">
                박민수
                <img src="https://i.imgur.com/5qtrmGg.gif">
              </td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="border-right: 0px; border-top: 0px; text-align: center;">사업장소재지</td>
              <td colspan="3" class="cell-style" style="text-align: left; padding-left: 10px; border-top: 0px;">경기도 수원시 영통구 센트럴타운로 107 1층 47호</td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="border-right: 0px; border-top: 0px; text-align: center;">업&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;태</td>
              <td class="cell-style" style="text-align: left; padding-left: 10px; border-right: 0px; border-top: 0px;">도소매</td>
              <td class="cell-style header-cell-style" style="border-right: 0px; border-top: 0px; text-align: center;">종&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</td>
              <td class="cell-style" style="text-align: left; padding-left: 10px; border-top: 0px;">컴퓨터및주변기기</td>
            </tr>
            <tr>
              <td height="35" class="cell-style header-cell-style" style="border-right: 0px; border-top: 0px; text-align: center;">전&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;화</td>
              <td class="cell-style" style="text-align: left; padding-left: 10px; border-right: 0px; border-top: 0px;">050-7368-9955</td>
              <td class="cell-style header-cell-style" style="border-right: 0px; border-top: 0px; text-align: center;">이&nbsp;메&nbsp;일</td>
              <td class="cell-style" style="text-align: left; padding-left: 10px; border-top: 0px;">admin@pckorea.co.kr</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>


    <div style="display: flex; margin-bottom: 5px;">
      <div style="flex: 29;">
        <tbody>
          <tr>
            <td class="esti-price-title" height="33px">견적금액</td>
          </tr>
        </tbody>
      </div>
      <div style="width: 3px;"></div>
      <div style="flex: 44;">
        <tbody>
          <tr>
            <td class="esti-price-price" height="33px" style="font-size: 14px;"> {{ formatPrice(TotalPrice) }}원 </td>
          </tr>
        </tbody>
      </div>
    </div>



    <tbody>
      <tr height="23">
        <td width="90" class="table-header" style="border-right: 0px;">구 분</td>
        <td width="470" class="table-header" style="border-right: 0px;">상 품 명</td>
        <td width="60" class="table-header" style="border-right: 0px;">판 매 가</td>
        <td width="40" class="table-header" style="border-right: 0px;">수 량</td>
        <td class="table-header">합 계</td>
      </tr>

      <template v-for="(item, index) in itemsdetail" :key="index">
        <tr height="35">
          <td width="90" class="table-item" style="font-size: 10px; border-top: 0px; border-right: 0px;">{{ item.item_category }}</td>
          <td width="360" class="table-item" style="text-align: left; padding-left: 5px; padding-right: 5px; border-top: 0px; border-right: 0px;">{{ item.item_nm }}</td>
          <td width="80" class="table-item" style="border-top: 0px; border-right: 0px;">{{ formatPrice(item.sale_price) }}</td>
          <td width="60" class="table-item" style="border-top: 0px; border-right: 0px;">{{ item.cart_qty }}</td>
          <td width="80" class="table-item" style="border-top: 0px">{{ formatPrice(item.sale_price * item.cart_qty) }}</td>
        </tr>
      </template>

      
      <template v-if="itemsdetail.length < 18">
        <tr v-for="index in 18 - itemsdetail.length" :key="'empty-' + index" height="35">
          <td width="90" class="table-item" style="border-top: 0px; border-right: 0px;"></td>
          <td width="360" class="table-item" style="border-top: 0px; border-right: 0px;"></td>
          <td width="80" class="table-item" style="border-top: 0px; border-right: 0px;"></td>
          <td width="60" class="table-item" style="border-top: 0px; border-right: 0px;"></td>
          <td width="80" class="table-item" style="border-top: 0px"></td>
        </tr>
      </template>


      
    </tbody>

    <div style="height: 5px;"></div>

    <table class="table-style">
      <tbody>
        <tr>
          <td width="85" height="35" rowspan="5" class="cell-style header-cell-style" style="text-align: center; border-right: 0px;">Remark</td>
        </tr>
        
        <tr>
          <td class="cell-style" rowspan="2">
            <div style="text-align: left; padding-left: 5px;">
              - 본 견적금액은 VAT포함 견적입니다.<br>
              - 해당 견적서의 유효기간은 1일입니다<br>
              - 발주시 유선/방문 상담을 통해 발주 부탁드립니다
            </div>

          </td>
          <td class="cell-style header-cell-style" style="text-align: center; border-left: 0px; border-bottom: 0px;">계좌번호</td>
        </tr>

        <tr>
          <td class="cell-style" style="text-align: center; border-left: 0px;">
            국민은행 227501-04-399849<br><br>
            예금주 : 박민수(삼진네트웍스)
          </td>
        </tr>


      </tbody>
    </table>


  </v-container>
</v-container>

</v-card>

</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { toJpeg } from 'html-to-image';

export default {
  props: {
    selectedRowData: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const loading = ref(false);
    const a4Container = ref(null);
    const itemsdetail = ref([]);
    const todayYYYYMMDD = ref ('');
    const todayYYYYMMDDHHMM = ref('');

    const TotalPrice = ref(0);
    const calculate_sum_item_price = () => {
      // 총금액 초기화
      TotalPrice.value = 0;
      // cartItems 배열 순회하여 sum_price를 합산
      for (const cartItem of itemsdetail.value) {
        TotalPrice.value += cartItem.sum_price;
      }
    };

    const printContent = () => {
      loading.value = true;
      html2canvas(a4Container.value.$el, {
        scale: 10,
        useCORS: true, // 추가
        backgroundColor: '#fff', // 추가
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');

        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <title>견적번호 : ${props.selectedRowData.esti_no}</title>
            </head>
            <body>
              <center>
              <img src="${imgData}" style="width: 100%; height: auto;">
              </center>
            </body>
          </html>
        `);

        printWindow.document.close();
        loading.value = false;
        //printWindow.print();
      });
    };

      const htmltoImage = () => {
        loading.value = true;
        nextTick(() => {
          const container = a4Container.value.$el;

          // html-to-image의 toJpeg 호출
          toJpeg(container, { quality: 0.95 })
            .then(function (dataUrl) {
              // 다운로드 링크 생성 및 클릭
              const link = document.createElement('a');
              link.download = `견적서_삼진네트웍스`+todayYYYYMMDD.value+`.jpg`;
              link.href = dataUrl;
              link.click();
              loading.value = false;
            })
            .catch(function (error) {
              loading.value = false;
              console.error('Error generating image:', error);
            });
        });
      };

      const htmltoPDF = () => {
        loading.value = true;
        nextTick(() => {
          const container = a4Container.value.$el;
          html2canvas(container, { scale: 2, useCORS: true, backgroundColor: '#fff' })
            .then(canvas => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
              });

              const imgWidth = 210;
              const pageHeight = 297;
              let imgHeight = (canvas.height * imgWidth) / canvas.width;

              // 이미지 높이가 A4 페이지 크기를 초과할 경우 비율 조정
              if (imgHeight > pageHeight) {
                const ratio = pageHeight / imgHeight;
                imgHeight *= ratio;
              }

              let position = 0;

              pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

              // 추가 페이지 추가 여부 결정
              if (imgHeight > pageHeight) {
                let heightLeft = imgHeight - pageHeight;
                while (heightLeft > 0) {
                  position = -heightLeft; // 새 페이지의 시작 위치 설정
                  pdf.addPage();
                  pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                  heightLeft -= pageHeight;
                }
              }

              pdf.save(`견적서_삼진네트웍스${todayYYYYMMDD.value}.pdf`);
              loading.value = false;
            })
            .catch(error => {
              loading.value = false;
              console.error('Error generating PDF:', error);
            });
        });
      };


    const createTodayYYYYMMDD = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const date = today.getDate();
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      const dayIndex = today.getDay();
      const days = ['일', '월', '화', '수', '목', '금', '토'];
      const day = days[dayIndex];
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const formattedDate = date < 10 ? `0${date}` : `${date}`;
      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      todayYYYYMMDD.value =  `${year}년 ${formattedMonth}월 ${formattedDate}일 ${day}요일`;
      todayYYYYMMDDHHMM.value = `${year}-${formattedMonth}-${formattedDate} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const formatPrice = (price) => {
      // price를 숫자로 파싱한 후, toLocaleString을 사용하여 한국 원 단위 포맷으로 변경
      const formattedPrice = Number(price).toLocaleString('ko-KR');
      return `${formattedPrice}`; // '원' 문자열을 추가하여 반환
    };

    onMounted(() => {
      itemsdetail.value = props.selectedRowData.cartItems;

      calculate_sum_item_price();
      createTodayYYYYMMDD();
    });

    return {
      a4Container, itemsdetail, todayYYYYMMDD, TotalPrice,
      printContent, htmltoImage, htmltoPDF, loading,
      createTodayYYYYMMDD, todayYYYYMMDDHHMM, formatPrice, calculate_sum_item_price,
    };
  },
};
</script>

<style scoped>

.a4-container {
  width: 210mm;
  height: 297mm;
  max-width: 100%;
  background-color: #fff;
}

.quote-container {
  background-color: #ffffff; /* 흰색 배경색 */
  border-bottom: 2px solid #000000;
}

.table-style {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5px;
}

.cell-style {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 5px;
  word-break: keep-all;
  letter-spacing: 0.7px;
  font-size: 12px;
}


.esti-price-title {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  word-break: keep-all;
  letter-spacing: 0.7px;
  font-size: 11px;
  background-color: #eee;
  font-weight: bold;
  letter-spacing: 1px;
  width: 1200px;
  font-family: 'Malgun Gothic', sans-serif;
}


.esti-price-price {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 5px;
  text-align: right;
  padding-right: 15px;
  word-break: keep-all;
  letter-spacing: 0.7px;
  font-size: 11px;
  background-color: #fff;
  font-weight: bold;

  width: 1200px;
}

.header-cell-style {
  background-color: #eee;
  font-weight: bold;
  letter-spacing: 1px;
}

.image-cell-style {
  position: relative;
}

.image-cell-style img {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1;
  right: 0;
  bottom: 0;
}

.custom-send {
  font-size: 8pt !important;
}
.custom-subheader {
  font-size: 7pt !important;
}
.table-header {
  background-color: #eee;
  border: 1px solid #ccc;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
  font-size: 11.5px;
}

.table-item {
  background-color: #ffffff;
  border: 1px solid #ccc;
  text-align: center;
  box-sizing: border-box;
  font-size: 11.5px;
}

.print-button {
  margin-right: 10px;
  margin-bottom: 10px; /* 버튼과 테이블 사이의 여백 */
  background-color: mediumseagreen; /* 파란색 배경 */
  color: white; /* 흰색 텍스트 */
}



</style>
