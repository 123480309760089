<template>
  <banner1 />
  <banner2 />
  <banner3 />
  <banner4 />
  <banner7 />
  <banner8 />
</template>

<script>
import banner1 from './ubuntu_body_banner1.vue';
import banner2 from './ubuntu_body_banner2.vue';
import banner3 from './ubuntu_body_banner3.vue';
import banner4 from './ubuntu_body_banner4.vue';
import banner7 from './ubuntu_body_banner7.vue';
import banner8 from './ubuntu_body_banner8.vue';

export default {
  components: {
    banner1,
    banner2,
    banner3,
    banner4,
    banner7,
    banner8,
  },
setup() {

}
}
</script>
