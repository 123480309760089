<template>
    <v-card
    elevation="0"
    style="padding: 10px"
    >
    <v-card
        width="780"
    >
        <template v-slot:title>
        <span class="font-weight-black">
            <v-icon icon="mdi-information" color="purple"/>
            렌탈 가능 대상
        </span>
        </template>

        <v-card-text class="bg-surface-light pt-4">
            B2B 서비스로 개인의 경우 렌탈이 불가능합니다
        </v-card-text>
    </v-card>

    <v-card
        width="780"
    >
        <template v-slot:title>
        <span class="font-weight-black">
            <v-icon icon="mdi-screwdriver" color="green"/>
            AS 안내
        </span>
        </template>
        

        <v-card-text class="bg-surface-light pt-4">
            하드웨어 및 소프트웨어의 기능상의 장애로 인한 상태 경우 대체 장비를 지급하고, A/S를 대행하여 처리하고있으며<br>
            귀책사유에 따라 장치 수리 요금을 청구할 수 있습니다<br>
            ※ 소모품 소모 교환 경우 (프린터)<br>
            ※ 사용자 부주의 및 고객사 과실에 의한 기능장애/파손 고장<br>
            ※ 임의 제품분해/개조 또는 임의 수리된 경우<br>
            <br>
            * 렌탈 제품의 경우 하드웨어에 대해서만 보증이 이뤄지며 소프트웨어 또는 저장된 DATA는 보증되지않습니다<br>
        </v-card-text>
    </v-card>

    <v-card
        width="780"
    >
        <template v-slot:title>
        <span class="font-weight-black">
            <v-icon icon="mdi-truck" color="blue"/>
            배송기간 및 배송구역 안내
        </span>
        </template>
        

        <v-card-text class="bg-surface-light pt-4">
            서울 및 경기도 외 서비스 불가<br>
            삼진네트웍스 직배송 설치
        </v-card-text>
    </v-card>

    </v-card>
</template>