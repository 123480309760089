// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MainIndex from '@/views/main_index.vue';
import ContactIndex from '@/views/main_contact.vue';
import EstimateIndex from '@/views/main_estimate.vue';
import RecommendComputer from '@/views/main_recommend_pc.vue';
import LoginIndex from '@/views/invisible/admin_login.vue';
import Rental from '@/views/main_rental.vue';
import Purchase from '@/views/main_purchase.vue';
import Ubuntu from '@/views/cloud/main_ubuntu.vue'
import Nextcloud from '@/views/cloud/main_nextcloud.vue'
import Remotesupport from '@/views/cscenter/main_remotesupport.vue'
// import paymentTest from '@/views/main_payment_test.vue';
// import login from '@/views/main_member_login.vue';
// import memberjoin from '@/views/main_member_join.vue';


const routes = [
  {
    path: '/',
    name: 'MainIndex',
    component: MainIndex
  },
  {
    path: '/contact',
    name: 'ContactIndex',
    component: ContactIndex
  },
  {
    path: '/estimate',
    name: 'EstimateIndex',
    component: EstimateIndex
  },
  {
    path: '/adminlogin',
    name: 'LoginIndex',
    component: LoginIndex
  },
  {
    path: '/recommend',
    name: 'RecommendComputer',
    component: RecommendComputer,
  },
  {
    path: '/rental',
    name: 'Rental',
    component: Rental,
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase,
  },
  {
    path: '/ubuntu',
    name: 'Ubuntu',
    component: Ubuntu,
  },
  {
    path: '/nextcloud',
    name: 'Nextcloud',
    component: Nextcloud,
  },
  {
    path: '/remotesupport',
    name: 'Remotesupport',
    component: Remotesupport,
  },
  
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: login,
  // },
  // {
  //   path: '/join',
  //   name: 'memberjoin',
  //   component: memberjoin,
  // },
  // {
  //   path: '/test',
  //   name: 'paymentTest',
  //   component: paymentTest,
  // }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
