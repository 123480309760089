<template>
    <div>
      <Header :is-hovering="isHovering" :background-color="backgroundColor" />
      <HeaderSub :user-permission="user_permission" :componentStyles="componentStyles"/>
      
      <MainBody />
      
      
    </div>
  </template>
  
<script>
import Header from '@/components/layout/header/header_default.vue';
import HeaderSub from '@/components/layout/sub/subpage_header_support.vue';
import MainBody from '@/components/pages/rental/rental_body_main.vue';

import { ref, onMounted, onUnmounted } from 'vue';
  
export default {
    components: {
        Header,
        HeaderSub,
        MainBody,
    },
    setup() {
      const isHovering = ref(false);
      const backgroundColor = ref('transparent');
      const imgHeight = ref(0);

      const componentStyles = ref({
        HeaderBackgroundColor: '#0D5CAB',
        HeaderSubBackgroundColor: '#35465C',
        TitleName: '기업용 렌탈'
      });
  
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // 스크롤이 0 이상일 때 배경색을 white로 변경
        if (scrollPosition > 0) {
          backgroundColor.value = 'white';
        } else {
          backgroundColor.value = 'transparent';
        }
      };
  
      const calculateImgHeight = () => {
        imgHeight.value = window.innerHeight + 66;
      };
  
      onMounted(() => {
        calculateImgHeight(); // 컴포넌트가 마운트되면 이미지 높이를 계산
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', calculateImgHeight); // 윈도우 크기가 변경될 때마다 이미지 높이를 다시 계산
      });
  
      onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', calculateImgHeight);
      });
  
      return { isHovering, backgroundColor, imgHeight, componentStyles };
    }
  };
  </script>
  
  <style scoped>
  .text-overlay {
    position: absolute;
    top: 120px;
    left: 120px;
    color: white;
    font-size: 45px;
    font-weight: 900;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  </style>
  