<template>
  <div class="container">
    <v-card
      class="card"
      :style="{
        width: isMobile ? '100%' : '100%',
        padding: isMobile ? '10px' : '0px',
        height: isMobile ? '700px' : '500px',
        marginTop: isMobile ? '5px' : '0px',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
      }"
      style="
      background-color: #EBEAEA;
      "
      elevation="0"
    >
    
      <!-- Text section (50% width) -->
      <div class="image-section" style="justify-content: center; text-align: center;" :style="{
        paddingTop: isMobile ? '20px' : '100px',
      }">
        <div
        :style="{
          fontSize: isMobile ? '30px' : '35px',
          fontWeight: isMobile ? '900' : '900',
        }">
          고객 맞춤형 최적화 VPS 호스팅 솔루션
        </div>

        
        <v-container
          style="text-align: left;"
        >
        <v-row align="center" justify="center" dense>
          <v-col cols="12" md="6">
            <v-card
              class="mx-auto"
              title="무제한 서버 트래픽"
            >
              <template v-slot:prepend>
                <v-icon color="primary" icon="mdi-server"></v-icon>
              </template>
              <template v-slot:append>
                <v-icon color="success" icon="mdi-check-circle"></v-icon>
              </template>
              <v-card-text>트래픽 제한 없이 경제적으로 운영할 수 있는 무제한 대역폭을 제공합니다. 서버 운영에 제약 없이 자유롭게 활용하세요.</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              class="mx-auto"
              title="다년간의 시스템 유지보수 경험"
            >
              <template v-slot:prepend>
                <v-icon color="primary" icon="mdi-account-cog"></v-icon>
              </template>
              <template v-slot:append>
                <v-icon color="success" icon="mdi-check-circle"></v-icon>
              </template>
              <v-card-text>서버 제작부터 호스팅까지, 축적된 하드웨어 및 시스템 유지보수 경험을 바탕으로 안정적이고 효율적인 서버 운영을 보장합니다.</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              class="mx-auto"
              title="직접 운영되는 서버 호스팅"
            >
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-server-security"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:append>
                <v-icon color="success" icon="mdi-check-circle"></v-icon>
              </template>
              <v-card-text>직접 운영되는 서버 환경에서 트래픽 관리와 데이터 보호를 보장하며, 정기적인 백업을 통해 데이터를 안전하게 유지합니다.</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              class="mx-auto"
              title="IT 전문 업체"
            >
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-cogs"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:append>
                <v-icon color="success" icon="mdi-check-circle"></v-icon>
              </template>
              <v-card-text>컴퓨터 하드웨어 제조부터 유통 및 사후 서비스까지, 종합적인 IT 솔루션을 제공하고있으며 장애가 발생하더라도 빠른 처리가 가능합니다</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        </v-container>
          


      </div>
    </v-card>
  </div>
</template>


<script>
import { ref, computed, onMounted } from 'vue';

export default {
  methods: {
    redirectToContact() {
      this.$router.push('/contact');
    }
  },
  setup() {
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value)
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}



/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
