<template>
    <v-card
        :height="imgHeight"
        :style="{
            overflowY: 'auto' 
        }"
    >
        <v-overlay :model-value="loading" class="align-center justify-center">
            <p style="color: white; font-size: 22px; text-align: center;">
                잠시만 기다려주세요
            </p>
            <br>
            <v-progress-circular color="primary" size="200" indeterminate></v-progress-circular>
        </v-overlay>
        <v-data-table
            :items="filteredItems"
            :headers="headers"
            style="overflow-y: auto; height: 100%;"
        >
            <template v-slot:headers>
            </template>
            <template v-slot:[`item.buy_link1`]="{ item }">

                <v-dialog
                    width="auto" height="auto" style="overflow: auto;"
                    v-model="item_detail_checked"
                >
                    <v-card>
                        <itemDetails
                            v-if="item.item_brand === '조립컴퓨터'"
                            :selectedItems="recommend_pc_item"
                            :selectedDetailItems="itemsDetail"
                            :detail_image="item.item_image_link"
                            :pc_no="pc_no"
                            :pc_nm="item_name"
                            :pc_sub_nm="sub_name"
                            :pc_os="pc_os"
                            :thumnailThemeColor="thumnailThemeColor"
                            :rentalPageYN="true"
                        />
                        <itemDetails2
                            v-if="item.item_brand !== '조립컴퓨터'"
                            :selectedItems="recommend_pc_item"
                            :selectedDetailItems="itemsDetail"
                            :detail_image="item.item_image_link"
                            :pc_no="item.pc_no"
                            :pc_nm="item.pc_nm"
                            :pc_sub_nm="item.pc_sub_nm"
                            :thumnailThemeColor="thumnailThemeColor"
                        />
                        <v-card-actions>
                            월&ensp;<span style="font-size: 22px; font-weight: 800;">{{ formatPrice(selectPCSaleprice) }}</span>원
                            <v-spacer></v-spacer>
                            
                            <v-btn
                                text="장바구니"
                                @click="goToStore()"
                            ></v-btn>
                            <v-btn
                                text="닫기"
                                @click="item_detail_checked = false"
                            ></v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
                
                <v-hover v-slot="{ isHovering, props }">
                    <v-card
                        :class="{ 'on-hover': isHovering }"
                        :elevation="isHovering ? 12 : 2"
                            v-bind="props"
                            style="position: relative; display: inline-block; cursor: pointer;"
                        @click="item_detail_checkedToggle(item)"
                    >
                    <div style="position: relative; width: 300px; height: 300px;">
                        <img :src="item.item_image_link" width="300" height="300">
                        <div 
                            v-if="isHovering && !isMobile" 
                            :style="{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: '23px',
                                pointerEvents: 'none',
                            }"
                        > + 더보기 </div>
                    </div>
                    </v-card>
                </v-hover>
            </template>
            <template v-slot:[`item.item_nm`]="{ item }">
                
                <h2 class="text-h5 font-weight-black text-orange">{{ item.sale_item_category }}</h2>

                {{ item.item_brand }} - {{ item.item_nm }} / {{ item.item_id }}
                <br>
                <br>
                <div v-if="!isMobile">
                    {{ truncateAndFindLastSlash(item.item_detail) }}
                </div>
                <br>
                <br>
                <v-row>
                    <v-spacer></v-spacer>
                    <h2 class="text-h7 font-weight-black text-black">
                        월 {{ formatPrice(item.sale_price) }}원
                    </h2>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!isMobile"
                        color="primary"
                        append-icon="mdi-chevron-double-right"
                        @click="searchDetail(item)"
                    >
                        장바구니
                        <template v-slot:append>
                            <v-icon color="#FFFFFF"></v-icon>
                        </template>
                    </v-btn>

                </v-row>
            </template>


        </v-data-table>
    </v-card>

</template>
<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { fetchGetData } from '../../common/JSfunction/usedFunction.js';
import itemDetails from '../recommend_pc/recommend_pc_item_details.vue';
import itemDetails2 from './rental_body_details.vue'

export default {
    components: {
        itemDetails,
        itemDetails2,
    },
    setup() {
        const store = useStore();
        const pcFilter = computed(() => store.getters.getPcFilter);

        const items = ref([]);
        const loading = ref(true);
        const isMobile = ref(false);

        const imgHeight = ref(0);
        const calculateImgHeight = () => {
            if(isMobile.value) {
                imgHeight.value = window.innerHeight - 65 - 50;
            } else if (!isMobile.value) {
                imgHeight.value = window.innerHeight - 65 - 50;
            } else {
                imgHeight.value = window.innerHeight
            }
        };

        const headers = ref([
            { title: '이미지', value: 'buy_link1'},
            { title: '이미지', value: 'item_nm'},
        ]);

        const fetchData = async () => {
            try {
                const apiPath = '/menu/item/ItemRental/manage/select';
                const queryParams = new URLSearchParams({
                    row_state: 0,
                    buy_link1: ''
                });
                const data = await fetchGetData(apiPath, queryParams.toString());
                items.value = data.map(item => ({
                    ...item,

                    id: item.pc_no,
                }));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const itemsDetail = ref([]);
        const fetchDataDetail = async() => {
            itemsDetail.value = [];
            try {
                const apiPath = '/menu/item/ItemRecommendPC/detail/manage/select';
                const data = await fetchGetData(apiPath, `pc_no=` + pc_no.value);
                itemsDetail.value = data.map(item => ({
                    ...item,
                    item_category: item.item_category,
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
            

        const filteredItems = computed(() => {
            setLoading();

            // 1. 필터링
            const itemsAfterFiltering = filterItems(items.value, pcFilter.value);
            // 2. 정렬
            const itemsAfterSorting = sortItems(itemsAfterFiltering, pcFilter.value);

            return itemsAfterSorting;
        });

        const filterItems = (items, filters) => {
            if (filters.length === 0) {
                // filters가 비어 있는 경우 모든 items를 반환하되, 판매가를 기준으로 정렬하여 반환
                return items.sort((a, b) => a.sale_price - b.sale_price);
            }

            const filteredItems = items.filter(item => {
                return filters.some(filter => {
                    if (filter.menu_code === 'Computer' && item.sale_item_category === '데스크탑 PC' && item.item_brand === filter.menu_name) {
                        return true;
                    }

                    if (filter.menu_code === 'Notebook' && item.sale_item_category === '노트북' && item.item_brand === filter.menu_name) {
                        return true;
                    }

                    if (filter.menu_code === 'Monitor' && item.sale_item_category === '모니터' && item.item_detail.includes(filter.menu_name)) {
                        return true;
                    }

                    if (filter.menu_code === 'Printer' && (item.sale_item_category === '레이저젯복합기' || item.sale_item_category === '잉크젯복합기') && item.sale_item_category === filter.menu_name) {
                        return true;
                    }

                    return false;
                });
            });

            // 필터링된 항목들을 판매가 기준으로 오름차순 정렬하여 반환
            return filteredItems.sort((a, b) => a.sale_price - b.sale_price);
        };





        const sortItems = (items, filters) => {

            // Orderby 필터를 찾아서 정렬을 수행합니다.
            const sortFilter = filters.find(filter => filter.menu_code === 'Orderby');
            if (!sortFilter) return items; // Orderby 필터가 없으면 정렬을 하지 않습니다.

            // 원본 배열을 변경하지 않도록 복사
            let sortedItems = [...items];

            if (sortFilter.menu_name === '최신등록순') {
                sortedItems.sort((a, b) => Number(b.pc_no) - Number(a.pc_no));
            } else if (sortFilter.menu_name === '낮은가격순') {
                sortedItems.sort((a, b) => {
                    // 데이터 타입 확인 및 변환
                    const priceA = Number(a.sale_price);
                    const priceB = Number(b.sale_price);
                    return priceA - priceB;
                });
            } else if (sortFilter.menu_name === '높은가격순') {
                sortedItems.sort((a, b) => {
                    // 데이터 타입 확인 및 변환
                    const priceA = Number(a.sale_price);
                    const priceB = Number(b.sale_price);
                    return priceB - priceA;
                });
            }

            return sortedItems;
        };






        const setLoading = () => {
            loading.value = true;
            setTimeout(() => {
                loading.value = false; // 2초 후에 loading 값을 false로 설정
            }, 700);
        };

        const formatPrice = (price) => {
        // price를 숫자로 파싱한 후, toLocaleString을 사용하여 한국 원 단위 포맷으로 변경
        const formattedPrice = Number(price).toLocaleString('ko-KR');
        return `${formattedPrice}`; // '원' 문자열을 추가하여 반환
        };

        const searchDetail = (item) => {
            store.dispatch('setItems', item)
        };

        const pc_no = ref('');
        const item_name = ref('');
        const sub_name = ref('');
        const pc_os = ref('');
        const item_detail_checked = ref(false);
        const recommend_pc_item = ref([]);
        const selectPCSaleprice = ref(0);
        const item_detail_checkedToggle = (item) => {
            if(item.item_brand === '조립컴퓨터') {
                pc_no.value = item.item_id.replace('PS-TCMBSJ','');
                item_name.value = item.item_nm;
                sub_name.value = item.item_detail;
                pc_os.value = item.item_category;

                fetchDataDetail(pc_no);
                if(isMobile.value) {
                    goToStore(item.smartstore_item_cd);
                } else {
                    recommend_pc_item.value = item;
                    selectPCSaleprice.value = item.sale_price || 0;
                }
            } else {
                itemsDetail.value = [];
                recommend_pc_item.value = item;
                selectPCSaleprice.value = item.sale_price || 0;
            }

            item_detail_checked.value = !item_detail_checked.value

        };

        const goToStore = async (param_item_cd) => {
            let param ;
            if(param_item_cd) {
                param = param_item_cd;
            } else {
                param = itemsDetail.value[0].smartstore_item_cd || '';
            }
            const url = `https://smartstore.naver.com/blackitem/products/${param}`;
            window.open(url, '_blank');
        }


        const checkIsMobile = () => {
            isMobile.value = window.innerWidth <= 768;
        };

        const truncateAndFindLastSlash = (item_detail) => {
            const maxLength = 250;
            if(item_detail.length <= maxLength) {
                return item_detail;
            }

            let truncatedDetail = item_detail.slice(0, maxLength);
            const lastSlashIndex = truncatedDetail.lastIndexOf('/');

            if(lastSlashIndex >= 0 && lastSlashIndex <= maxLength) {
                truncatedDetail = truncatedDetail.slice(0, lastSlashIndex);
            }
            
            return truncatedDetail;
        }

        onMounted(() => {
            checkIsMobile();
            fetchData();
            calculateImgHeight();
        });

        return{
            item_detail_checked, item_detail_checkedToggle, recommend_pc_item,
            items, loading, headers, imgHeight, setLoading,
            fetchData, formatPrice, searchDetail, filteredItems, filterItems, sortItems,
            itemsDetail, fetchDataDetail, goToStore, selectPCSaleprice, truncateAndFindLastSlash,
            isMobile: computed(() => isMobile.value),
            checkIsMobile, pc_no, item_name, sub_name, pc_os,
        }
    }
}
</script>