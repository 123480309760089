<template>

    <v-container
      class="text-overlay"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
        font-size: 55px;
        font-weight: 900;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        width: 100%;
      "
    >
      <v-row>
        <v-col>
          <v-row>
            원스톱 컴퓨팅
          </v-row>
          <v-row>
            솔루션 제공
          </v-row>
          <v-row>
            <v-btn variant="text" style="background-color: red;" color="white" @click="redirectToContact">Contact Us</v-btn>
          </v-row>
        </v-col>

      </v-row>
    </v-container>

</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  methods: {
    redirectToContact() {
      this.$router.push('/contact');
    }
  },
  setup() {
    const imgHeight = ref(0);

    const calculateImgHeight = () => {
      imgHeight.value = window.innerHeight + 1;
    };

    onMounted(() => {
      calculateImgHeight();
      window.addEventListener('resize', calculateImgHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', calculateImgHeight);
    });

    return { imgHeight };
  }
};
</script>

<style scoped>
.text-overlay {
  z-index: 1; /* Ensure the overlay is above the image */
}
</style>
