<template>
    <div
      v-if="isMobile"
      style="
        height: 50px;
        margin-top: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        padding-left: 10px;
      "
    >
      <span style="display: flex; justify-content: center;" @click="drawerToggle">
        <v-icon icon="mdi mdi-filter"></v-icon>조건검색
      </span>
    </div>
  <v-card :height="imgHeight"
  >
  <v-layout class="rounded rounded-md">
    <v-navigation-drawer :width="320" v-model="drawer">
      <LeftDrawer />
    </v-navigation-drawer>

    <v-main :style="{ minHeight: imgHeight + 'px'}">
      <v-row style="padding: 7px;">
        <v-col cols="12" sm="8">
          <ContentsLeft />
        </v-col>
        <v-col cols="12" sm="4" v-if="!isMobile">
          <ContentsRight />
        </v-col>
      </v-row>
    </v-main>

  </v-layout>

  </v-card>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import LeftDrawer from './rental_left_drawer.vue';
import ContentsRight from './rental_body_right.vue';
import ContentsLeft from './rental_body_left.vue';

export default {
  components: {
    LeftDrawer,
    ContentsRight,
    ContentsLeft,
  },
  setup() {
    const imgHeight = ref(0);
    const isMobile = ref(false);
    const isSmallScreen = ref(false);
    const drawer = ref(null);


    const calculateImgHeight = () => {
      if(isMobile.value) {
        imgHeight.value = window.innerHeight - 65 - 42;
      } else if (!isMobile.value) {
        imgHeight.value = window.innerHeight - 65 - 39;
      } else {
        imgHeight.value = window.innerHeight
      }
    };

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
      isSmallScreen.value = window.innerWidth <= 1300;

      if(isMobile.value) {
        drawer.value = false;
      } else {
        drawer.value = true;
      }
      

    };

    const drawerToggle = () => {
      drawer.value = !drawer.value;
    }

    onMounted(() => {
      checkIsMobile();
      calculateImgHeight();
      window.addEventListener('resize', checkIsMobile);
      window.addEventListener('resize', calculateImgHeight);
    });

    return {
      imgHeight, drawer,
      drawerToggle,
      calculateImgHeight, checkIsMobile,
      isMobile: computed(() => isMobile.value),
      isSmallScreen: computed(() => isSmallScreen.value),
      isDesktop: computed(() => !isMobile.value),
    };
  }
}
</script>