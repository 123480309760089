<template>
    <v-card rounded="0" width="auto" height="800"
      style="overflow: auto;"
    >
      <v-card
        width="800"
        ref="Container"
      >
      <v-card
        elevation="10"
        style="padding: 40px; margin: 10px; background-color: black; color: white;"
        width="100%"
        height="130"
      >
        <v-row style="display: flex; justify-content: center">
          <img src="https://cdn.samjinnetworks.com/images/web/main_logo_white.png">
        </v-row>
        <v-row>
          <v-col style="display: flex; justify-content: center">
            고객센터 : 0507-1374-6229<br>
            방문수령 : 경기도 수원시 영통구 센트럴타운로 107 월드스퀘어 1층 1-047호<br>
          </v-col>
        </v-row>

      </v-card>
      <br>

      <v-card
        width="780"
        height="max-content"
        class="rounded"
        style="
          background-color: #F2F2F2;
          margin: 10px;
          padding-top: 50px;
          padding-left: 20px;
          padding-right: 15px;
        "
      >

        <div style="font-size: 18px; font-weight: 400; display: flex; justify-content: center; padding-bottom: 50px">
          <b>{{ selectedItems.item_brand }} {{ selectedItems.item_nm }}</b>
        </div>

        <div style="display: flex; justify-content: center; padding-bottom: 100px">
          <v-card
            class="rounded"
            elevation="22"
            style="display: flex; justify-content: center; align-items: center;"
          >
            <img :src="selectedItems.item_image_link" style="width: 500px; height: 500px;">
            <!-- 중앙 워터마크 이미지 -->
            <div
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: 0.1  // 워터마크 효과를 위한 투명도 설정
              }"
            >
              <img src="https://cdn.samjinnetworks.com/images/web/main_logo.png" alt="워터마크" style="width: 550px;" />
            </div>
          </v-card>
        </div>

        <v-col v-for="(item, index) in selectedDetailItems" :key="index" cols="12" md="12">
          <v-card
            class="mx-auto"
            :title="item.sale_item_category"
          >
            <template v-slot:prepend>
              <img :src="item.item_image_link" style="width: 150px;">
            </template>
            <template v-slot:subtitle>
              {{ item.item_nm }} <b style="color: chocolate;" v-if="item.item_qty !== 1"> {{ item.item_qty }}EA </b>
            </template>

          <v-card-text v-if="item.item_qty === 1">
            {{ item.item_detail }}
          </v-card-text>
          </v-card>
        </v-col>

      </v-card>
      <v-card
        elevation="5"
        style="padding: 20px; margin: 10px"
        width="780"
      >
        <v-row>
          <v-col>
            {{ selectedItems.item_detail }}
          </v-col>
        </v-row>
      </v-card>

      <div v-html="convertDetailImage(selectedItems.item_detail_image_link)" style="width: 100%;"></div>


      <QNA />

        
      </v-card>


    </v-card>
</template>

<script>
import { toJpeg } from 'html-to-image';
import { ref, nextTick, onMounted } from 'vue';
import QNA from './rental_body_details_qna.vue'

export default {
  components: {
      QNA,
  },
  props: {
    selectedItems: {
      type: Object,
      required: true,
    },
    selectedDetailItems: {
      type: Object,
      required: true,
    },
    detail_image: {
      type: String,
      required: true,
    },
    pc_no: {
      type: String,
      required: true,
    },
    pc_nm: {
      type: String,
      required: true,
    },
    pc_sub_nm: {
      type: String,
      required: true,
    },
    thumnailThemeColor: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const headers = ref([
      { width: 100, title: '카테고리', value: 'sale_item_category' },
      { width: 100, title: '브랜드', value: 'item_brand' },
      { title: '이미지링크', value: 'item_image_link' },
      { title: '상품명', value: 'item_nm' },
      { width: 60, title: '수량', value: 'item_qty' },
    ]);

    const Container = ref(null);
    const SpecContainer = ref(null);

    const htmltoImage = () => {
      nextTick(() => {
        const container = Container.value.$el;
        console.log(container);

        // html-to-image의 toJpeg 호출
        toJpeg(container, { quality: 0.95 })
          .then(function (dataUrl) {
            // 다운로드 링크 생성 및 클릭
            const link = document.createElement('a');
            link.download = `PS-TCMBSJ`+props.selectedItems.pc_no+`_detail.jpg`;
            link.href = dataUrl;
            link.click();
          })
          .catch(function (error) {
            console.error('Error generating image:', error);
          });
      });

      // SpecContainerhtmltoImage();
      
    };

    const SpecContainerhtmltoImage = () => {
      nextTick(() => {
        const container = SpecContainer.value.$el;
        console.log(container);

        // html-to-image의 toJpeg 호출
        toJpeg(container, { quality: 0.95 })
          .then(function (dataUrl) {
            // 다운로드 링크 생성 및 클릭
            const link = document.createElement('a');
            link.download = `PS-TCMBSJ`+props.pc_no+`_spec.jpg`;
            link.href = dataUrl;
            link.click();
          })
          .catch(function (error) {
            console.error('Error generating image:', error);
          });
      });
    };

    const truncateAndFindLastSlash = (item_detail) => {
      const maxLength = 160;
      if(item_detail.length <= maxLength) {
        return item_detail;
      }

      let truncatedDetail = item_detail.slice(0, maxLength);
      const lastSlashIndex = truncatedDetail.lastIndexOf('/');

      if(lastSlashIndex >= 0 && lastSlashIndex <= maxLength) {
        truncatedDetail = truncatedDetail.slice(0, lastSlashIndex);
      }
      
      return truncatedDetail;
    }

    const convertDetailImage = (itemdetailimagelink) => {
      // Add width attribute to all img tags
      const modifiedContent = itemdetailimagelink.replace(/<img /g, '<img style="width: 100%; height: auto;" ');
      const htmlContent = `
        <center>
          ${modifiedContent}
        </center>
      `;

      return htmlContent;
    };


    onMounted(() => {

    });


    return {
      headers, Container, SpecContainer, htmltoImage, convertDetailImage,
      SpecContainerhtmltoImage, truncateAndFindLastSlash,
      
    }
  }
};
</script>
<style scoped>
/* Ensure any images within the HTML string are responsive */
div[v-html] img {
  max-width: 100%;
  height: auto;
}
</style>