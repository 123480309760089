<template>
    <v-card rounded="0" width="auto" height="800"
      style="overflow: auto;"
    >
      <v-card
        width="800"
        ref="Container"
      >
      <v-card
        elevation="10"
        style="padding: 40px; margin: 10px; background-color: black; color: white;"
        width="100%"
        height="130"
      >
        <v-row style="display: flex; justify-content: center">
          <img src="https://cdn.samjinnetworks.com/images/web/main_logo_white.png">
        </v-row>
        <v-row>
          <v-col style="display: flex; justify-content: center">
            고객센터 : 0507-1374-6229<br>
            방문수령 : 경기도 수원시 영통구 센트럴타운로 107 월드스퀘어 1층 1-047호<br>
          </v-col>
        </v-row>

      </v-card>
      <br>

      <v-card
        width="780"
        height="max-content"
        class="rounded"
        style="
          background-color: #F2F2F2;
          margin: 10px;
          padding-top: 50px;
          padding-left: 20px;
          padding-right: 15px;
        "
      >
        <div v-if="selectedItems.cpu_series" style="font-size: 42px; font-weight: 900; display: flex; justify-content: center;">
          {{ selectedItems.cpu_series }} series
        </div>
        <div v-if="selectedItems.pc_no" style="font-size: 18px; font-weight: 400; display: flex; justify-content: center; padding-bottom: 50px">
          {{ selectedItems.pc_nm }}&nbsp;<b>PS-TCMBSJ{{ selectedItems.pc_no }}</b>
        </div>
        <div v-else style="font-size: 18px; font-weight: 400; display: flex; justify-content: center; padding-bottom: 50px">
          {{ pc_name }}&nbsp;<b>PS-TCMBSJ{{ pc_number }}</b>
        </div>
        
        <div style="display: flex; justify-content: center; padding-bottom: 100px">
          <v-card
            class="rounded"
            elevation="22"
            style="display: flex; justify-content: center; align-items: center;"
          >
            <img :src="selectedItems.item_image_link" style="width: 500px; height: 500px;">
            <!-- 중앙 워터마크 이미지 -->
            <div
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: 0.1  // 워터마크 효과를 위한 투명도 설정
              }"
            >
              <img src="https://cdn.samjinnetworks.com/images/web/main_logo.png" alt="워터마크" style="width: 550px;" />
            </div>

            <div
              :style="{
                position: 'absolute',
                top: '70%',
                right: '0',
                width: '65%',
              }"
              style="text-align: right; background-color: chocolate; height: max-content; color: white; font-size: 14px;"
            > 
                ※ 제품의 이해를 돕기위한 케이스 연출 모습으로
                <br>시스템 구성에 따라 연출 외관의 차이가 있습니다
            </div>

            <!-- 이미지하단 서브텍스트 -->
            <div
              :style="{
                position: 'absolute',
                bottom: '0',
                width: '800px',
                textAlign: 'center',
                backgroundColor: '#000000',
                color: 'white',
                padding: '12px',
              }"
            >
              <v-row>
                <v-col
                  style="
                    background-color: transparent;
                    color: white;
                    font-size: 16px;
                    font-weight: 600;
                  "
                >
                  <div v-if="selectedItems.pc_sub_nm" style="padding: 10px">
                    {{ selectedItems.pc_sub_nm }}
                  </div>
                  <div v-else style="padding: 10px">
                    {{ pc_sub_name }}
                  </div>
                  
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>

        <v-col v-for="(item, index) in selectedDetailItems" :key="index" cols="12" md="12">
          <v-card
            class="mx-auto"
            :title="item.sale_item_category"
          >
            <template v-slot:prepend>
              <img :src="item.item_image_link" style="width: 150px;">
            </template>
            <template v-slot:subtitle>
              {{ item.item_nm }} <b style="color: chocolate;" v-if="item.item_qty !== 1"> {{ item.item_qty }}EA </b>
            </template>

          <v-card-text v-if="item.item_qty === 1">
            {{ truncateAndFindLastSlash(item.item_detail) }}
          </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card style="background-color: black; color: white;">
            <v-card-title style="text-align: center;">
              운영체제 안내
            </v-card-title>
            <v-card style="background-color: white; padding: 30px; margin: 15px;">
              <div v-if="selectedItems.pc_os === 'Ubuntu Desktop'" style="padding: 30px; text-align: center;">
                해당 제품은 {{ selectedItems.pc_os }} 설치되어 출고처리됩니다<br><br>

                Ubuntu is a trademark of Canonical Limited and<br>
                is used with the permission of Canonical Limited.<br>
                <br>
                <b>Ubuntu는 Canonical Limited의 상표이며<br>
                  Canonical Limited의 허가를 받아 사용됩니다.</b>
              </div>

              <div v-if="selectedItems.pc_os === 'Ubuntu Server'" style="padding: 30px; text-align: center;">
                해당 제품은 {{selectedItems.pc_os}} 설치되어 출고처리됩니다<br><br>

                Ubuntu is a trademark of Canonical Limited and<br>
                is used with the permission of Canonical Limited.<br>
                <br>
                <b>Ubuntu는 Canonical Limited의 상표이며<br>
                  Canonical Limited의 허가를 받아 사용됩니다.</b>
              </div>

              <div v-if="selectedItems.pc_os === 'Windows 11 Home'" style="padding: 30px; text-align: center;">
                해당 제품은 {{ selectedItems.pc_os }} 설치되어 출고처리됩니다<br><br>

                라이센스 패키지는 개봉하여 제품에 설치됩니다<br>
                개봉된 패키지는 같이 동봉되어 출고됩니다
              </div>

              <div v-if="selectedItems.pc_os === 'Windows 11 Pro'" style="padding: 30px; text-align: center;">
                해당 제품은 {{ selectedItems.pc_os }} 설치되어 출고처리됩니다<br><br>

                라이센스 패키지는 개봉하여 제품에 설치됩니다<br>
                개봉된 패키지는 같이 동봉되어 출고됩니다
              </div>

              <div v-if="computer_os === 'Windows 11 Home'" style="padding: 30px; text-align: center;">
                해당 제품은 {{ computer_os }} 설치되어 출고처리됩니다<br><br>
              </div>

              <div v-if="computer_os === 'Windows 11 Pro'" style="padding: 30px; text-align: center;">
                해당 제품은 {{ computer_os }} 설치되어 출고처리됩니다<br><br>
              </div>


              <div v-if="selectedItems.pc_os === ''" style="padding: 30px; text-align: center;">
                해당 제품은 운영체제가 포함되지않은 프리도스 제품입니다
              </div>
            </v-card>


          </v-card>
        </v-col>


        
        
      </v-card>


        <v-card
          elevation="5"
          style="padding: 20px; margin: 10px"
          width="780"
        >
          <v-row>
            <v-col cols="1">
              <v-img
                src="https://i.namu.wiki/i/OHrIdweMvGLHzxaieSfIPTpTRbizW0EVea_QDe1ypdxDEwNWUy6h-0r9squi0ZUXC6OIJ5CRqXFOOyKea6mrPA.svg"
                width="50"
              >
              </v-img>
            </v-col>
            <v-col>
              본 제품(컴퓨터)는 전자파 적합성인증평가 를 받은 내장 구성품을 사용하여 조립한 것으로
              완성품에 대한 전자파 적합성 평가는 받지않은 제품입니다.<br>
              ( 방송통신 기자재등의 적합성 평가에 관한 고시 제 18조 제1항 제1조 근거)
            </v-col>
          </v-row>
        </v-card>

        <v-card
          style="background-color: #E7E7E7;"
          width="800"
          ref="SpecContainer"
        >
          <v-card-title>
            구성장치
          </v-card-title>
          <v-data-table
            :items="selectedDetailItems"
            :headers="headers"
          >
            <template v-slot:headers>
            </template>
            <template v-slot:bottom>
            </template>

            <template v-slot:[`item.item_image_link`]="{ item }">
              <img :src="item.item_image_link" alt="Product Image" width="50" height="50" />
            </template>
            <template v-slot:[`item.item_qty`]="{ item }">
              {{ item.item_qty }}개
            </template>

          </v-data-table>

        </v-card>

        <br>
        
        <v-card
          v-if="rentalYN === false"
          elevation="0"
          style="padding: 10px"
        >

      
          <v-card
            width="780"
          >
            <template v-slot:title>
              <span class="font-weight-black">
                <v-icon icon="mdi-information" color="purple"/>
                교환 및 반품안내
              </span>
            </template>

            <v-card-text class="bg-surface-light pt-4">
              조립 데스크톱의 경우 주문제작상품으로 조립이 들어간경우 교환/반품이 불가합니다
            </v-card-text>
          </v-card>

          <v-card
            width="780"
          >
            <template v-slot:title>
              <span class="font-weight-black">
                <v-icon icon="mdi-screwdriver" color="green"/>
                AS 안내
              </span>
            </template>
            

            <v-card-text class="bg-surface-light pt-4">
              본체 무상보증기간 기본 1년이며 무상기한내에 제품 문제 발생시 무상 A/S처리(당사에서 지정된 택배사를 통해 입고가능.) <br>
              ※ 케이스 구조상 배송방법이 퀵배송, 방문수령으로 제한되는 제품은, 퀵입고시 배송비는 왕복 고객부담입니다. <br>
              - 제품 수령 후 7일 이내 제품불량 발생시 교환이나 환불에 필요한 운송비를 전액(왕복) 피씨코리아에서 부담 <br>
              - 제품 수령 후 7일 이상 30일 이내에 제품불량 발생시 교환에 필요한 운송비는 반액(편도) 피씨코리아에서 부담 <br>
              - 주문제작 상품으로 고객 변심에 의해 반품이 불가능합니다 <br>
            </v-card-text>
          </v-card>
          
          <v-card
            width="780"
          >
            <template v-slot:title>
              <span class="font-weight-black">
                <v-icon icon="mdi-truck" color="blue"/>
                배송기간 안내
              </span>
            </template>
            

            <v-card-text class="bg-surface-light pt-4">
              결제 확인 후 평균 3 영업일 이내 발송이 이뤄집니다.<br>
              주말 및 공휴일은 배송 기간에 포함되지 않습니다.
            </v-card-text>
          </v-card>

          <v-card
            width="780"
          >
            <template v-slot:title>
              <span class="font-weight-black">
                <v-icon icon="mdi-store" color="red"/>
                방문수령
              </span>
            </template>
            

            <v-card-text class="bg-surface-light pt-4">
              삼진네트웍스<br>
              주소 경기도 수원시 영통구 센트럴타운로 107 월드스퀘어 1층 47호
            </v-card-text>
          </v-card>

        </v-card>

        <v-card
          v-if="rentalYN === true"
          elevation="0"
          style="padding: 10px"
        >
          <QNA />
        </v-card>

      </v-card>

    </v-card>
</template>

<script>
import { toJpeg } from 'html-to-image';
import { ref, nextTick, onMounted } from 'vue';
import QNA from '../rental/rental_body_details_qna.vue'

export default {
  components: {
      QNA,
  },
  props: {
    selectedItems: {
      type: Object,
      required: true,
    },
    selectedDetailItems: {
      type: Object,
      required: true,
    },
    detail_image: {
      type: String,
      required: true,
    },
    pc_no: {
      type: String,
      required: true,
    },
    pc_nm: {
      type: String,
      required: true,
    },
    pc_sub_nm: {
      type: String,
      required: true,
    },
    thumnailThemeColor: {
      type: String,
      required: true,
    },
    pc_os: {
      type: String,
      required: true,
    },
    rentalPageYN: {
      type: Boolean,
      required: true,
    }
  },
  setup(props) {
    const headers = ref([
      { width: 100, title: '카테고리', value: 'sale_item_category' },
      { width: 100, title: '브랜드', value: 'item_brand' },
      { title: '이미지링크', value: 'item_image_link' },
      { title: '상품명', value: 'item_nm' },
      { width: 60, title: '수량', value: 'item_qty' },
    ]);
    const Container = ref(null);
    const SpecContainer = ref(null);

    const htmltoImage = () => {
      nextTick(() => {
        const container = Container.value.$el;
        console.log(container);

        // html-to-image의 toJpeg 호출
        toJpeg(container, { quality: 0.95 })
          .then(function (dataUrl) {
            // 다운로드 링크 생성 및 클릭
            const link = document.createElement('a');
            link.download = `PS-TCMBSJ`+props.selectedItems.pc_no+`_detail.jpg`;
            link.href = dataUrl;
            link.click();
          })
          .catch(function (error) {
            console.error('Error generating image:', error);
          });
      });

      // SpecContainerhtmltoImage();
      
    };

    const SpecContainerhtmltoImage = () => {
      nextTick(() => {
        const container = SpecContainer.value.$el;
        console.log(container);

        // html-to-image의 toJpeg 호출
        toJpeg(container, { quality: 0.95 })
          .then(function (dataUrl) {
            // 다운로드 링크 생성 및 클릭
            const link = document.createElement('a');
            link.download = `PS-TCMBSJ`+props.pc_no+`_spec.jpg`;
            link.href = dataUrl;
            link.click();
          })
          .catch(function (error) {
            console.error('Error generating image:', error);
          });
      });
    };

    const truncateAndFindLastSlash = (item_detail) => {
      const maxLength = 160;
      if(item_detail.length <= maxLength) {
        return item_detail;
      }

      let truncatedDetail = item_detail.slice(0, maxLength);
      const lastSlashIndex = truncatedDetail.lastIndexOf('/');

      if(lastSlashIndex >= 0 && lastSlashIndex <= maxLength) {
        truncatedDetail = truncatedDetail.slice(0, lastSlashIndex);
      }
      
      return truncatedDetail;
    }
    const pc_number = ref('');
    const pc_name = ref('');
    const pc_sub_name = ref('');
    const computer_os = ref('');
    const rentalYN = ref(false);

    onMounted(() => {
      rentalYN.value = false;
      if(props.pc_no !== ''){
        pc_number.value = props.pc_no;
        pc_name.value = props.pc_nm;
        pc_sub_name.value = props.pc_sub_nm;
        computer_os.value = props.pc_os;
        rentalYN.value = props.rentalPageYN;
      } 
    });




    return {
      headers, Container, SpecContainer, htmltoImage,
      SpecContainerhtmltoImage, truncateAndFindLastSlash,
      pc_number, pc_name, pc_sub_name, computer_os, rentalYN
    }
  }
};
</script>