<template>
    <div class="container">
      <v-card
        class="card"
        :style="{
          width: isMobile ? '100%' : '100%',
          padding: isMobile ? '10px' : '0px',
          height: isMobile ? '700px' : '500px',
          borderRadius: isMobile ? '0px' : '0px',
          marginTop: isMobile ? '0px' : '0px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center'
        }"
        style="
        background-color: #DBEDF7;
        "
        elevation="0"
      >
        <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <img src="/images/nextcloud/nextcloud-file-preview.png" :width="isMobile ? '700' : '700'">
        </div>
  
        <!-- Text section (50% width) -->
        <div class="text-section" style="flex: 1;">
          <div
          :style="{
            fontSize: isMobile ? '30px' : '55px',
            fontWeight: isMobile ? '900' : '900',
          }">
            넥스트클라우드 허브
          </div>
  
            <div
              :style="{
                fontSize: isMobile ? '15px' : '16px',
                fontWeight: isMobile ? '100' : '500',
              }"
            >
                Nextcloud Hub는 Nextcloud의 4가지 주요 제품인 Files, Talk, Groupware, Office를
                <br>단일 플랫폼으로 통합하여 협업 흐름을 최적화합니다.
            </div><br>
            <div
              :style="{
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: isMobile ? '100' : '300',
              }"
            >
                Nextcloud Files는 데스크톱, 모바일 및 웹 인터페이스를 통해
                <br>강력한 협업 기능을 갖춘 자체 호스팅 파일 저장 및 동기화 플랫폼을 제공합니다.
            </div>
            
  
  
        </div>
      </v-card>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  import { ref, computed, onMounted } from 'vue';
  
  export default {
    setup() {
      const router = useRouter();
      const isMobile = ref(false);
  
      const checkIsMobile = () => {
        isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
      };
  
      const goToestimate = () => {
        router.push('/estimate');
      }
  
      onMounted(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
      });
  
      return {
        goToestimate,
        isMobile: computed(() => isMobile.value),
        isDesktop: computed(() => !isMobile.value),
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
  }
  
  
  
  /* Styles for mobile */
  @media screen and (max-width: 768px) {
    .text-section {
      padding: 0px 20px 0 20px;
    }
  }
  </style>
  