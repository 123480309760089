<template>
    <v-list>
        <v-list-group
            v-for="(largeClassItem, index) in largeClass"
            :key="index"
            v-model="openMenus[largeClassItem.menu_code]"
        >
            <template v-slot:activator="{ props }">
                <v-list-item
                    v-bind="props"
                    :value="largeClassItem.menu_code"
                    :append-icon="openMenus[largeClassItem.menu_code] ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                    @click="toggleMenu(largeClassItem.menu_code)"
                    style="font-weight: 700; font-size: 15px;"
                >
                    {{ largeClassItem.menu_name }}
                </v-list-item>
                <v-divider color="#000000" width="100%"></v-divider>
            </template>
            
            <v-list-group
                v-for="(middleClassItem, index) in filteredMiddleClass(largeClassItem.menu_code)"
                :key="index"
                no-action
            >
                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        append-icon=""
                        @click="selectSubMenu(middleClassItem.menu_code, middleClassItem.menu_name, middleClassItem.checked)"
                        style="
                            font-weight: 300;
                        "
                    >
                    <v-icon color="primary" v-if="middleClassItem.checked === true && !priceOrderby" icon="mdi mdi-checkbox-marked"></v-icon>
                    <v-icon v-if="middleClassItem.checked === false" icon="mdi mdi-checkbox-blank-outline"></v-icon>

                    <span style="font-size: 15px; padding-left: 5px;">{{ middleClassItem.menu_name }}</span>
                    </v-list-item>
                </template>
            </v-list-group>
            
        </v-list-group>
    </v-list>
</template>
<script>
import { ref, onMounted } from 'vue';
import { fetchGetData } from '../../common/JSfunction/usedFunction.js';
import { useStore } from 'vuex';
    export default {
        setup() {
            const store = useStore();
            const largeClass = ref([
                { menu_code: 'Orderby', menu_name: '정렬기준' },
                { menu_code: 'processor', menu_name: '프로세서' },
                { menu_code: 'priceOrderby', menu_name: '금액대 별' },
                { menu_code: 'ramType', menu_name: '메모리규격' },
                { menu_code: 'ramGB', menu_name: '메모리용량' },
                { menu_code: 'os', menu_name: '운영체제' },
                { menu_code: 'graphicCard', menu_name: '그래픽카드' },
                { menu_code: 'SSD', menu_name: 'SSD용량' },
                { menu_code: 'HDD', menu_name: 'HDD용량' },
            ]);
            const openMenus = ref({});
                largeClass.value.forEach(item => {
                openMenus.value[item.menu_code] = false;
            });
            
            const middleClass = ref([
                { menu_code: 'priceOrderby', menu_name: '50만원미만', checked: false },
                { menu_code: 'priceOrderby', menu_name: '50만원~100만원', checked: false },
                { menu_code: 'priceOrderby', menu_name: '100만원~150만원', checked: false },
                { menu_code: 'priceOrderby', menu_name: '150만원~200만원', checked: false },
                { menu_code: 'priceOrderby', menu_name: '200만원~300만원', checked: false },
                { menu_code: 'priceOrderby', menu_name: '300만원이상', checked: false },

                { menu_code: 'Orderby', menu_name: '최신등록순', checked: true },
                { menu_code: 'Orderby', menu_name: '낮은가격순', checked: false },
                { menu_code: 'Orderby', menu_name: '높은가격순', checked: false },
            ]);

            const filteredMiddleClass = (menu_code) => {
            if (openMenus.value[menu_code]) {
                return middleClass.value.filter(item => item.menu_code === menu_code);
            }
            return [];
            };

            const toggleMenu = (menu_code) => {
                openMenus.value[menu_code] = !openMenus.value[menu_code];
            };

            const selectedCheckFillter = ref ([]);

            const selectSubMenu = (menu_code, menu_name, checked) => {
                const selectedItemChecked = middleClass.value.find(item => item.menu_code === menu_code && item.checked === true);
                if (selectedItemChecked) {
                    selectedItemChecked.checked = false;
                }

                const selectedItem = middleClass.value.find(item => item.menu_code === menu_code && item.menu_name === menu_name && item.checked === checked);
                if (selectedItem) {
                    selectedItem.checked = !selectedItem.checked;
                }

                // selectedCheckFillter에 현재 체크된 항목 추가
                const checkedItems = middleClass.value.filter(item => item.checked);
                selectedCheckFillter.value = checkedItems;

                store.dispatch('setPcFilter', checkedItems);
            };

        

            onMounted(() => {
                const apiPath = '/menu/item/ItemRecommendPC/fillter/options/data';
                selectSubMenu();

                fetchGetData(apiPath)
                    .then(data => {
                        // 데이터를 받아온 후 처리
                        data.forEach(item => {
                            // 각 항목을 middleClass에 추가
                            const menu_code = item.parts; // 예시 데이터에서 parts를 menu_code로 사용
                            const menu_name = item.filtered_data.trim(); // filtered_data를 menu_name으로 사용

                            // 중복 확인
                            const existingItem = middleClass.value.find(existing => existing.menu_code === menu_code && existing.menu_name === menu_name);
                            if (!existingItem) {
                                // 중복되지 않는 경우에만 추가
                                middleClass.value.push({
                                    menu_code,
                                    menu_name,
                                    checked: false
                                });
                            }
                        });
                        
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });

            });

            return {
                largeClass, openMenus, middleClass,
                filteredMiddleClass, toggleMenu, selectSubMenu, selectedCheckFillter,
            }
        }
    }

</script>