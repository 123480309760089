<template>
    <div>
      <Header :is-hovering="isHovering" :background-color="backgroundColor"/>
      <HeaderSub :componentStyles="componentStyles"/>
      <BodyMainBanner9 />
      <Banner2/>
      
      <Footer />
    </div>
  </template>
  
<script>
  import Header from '@/components/layout/header/header_default.vue';
  import HeaderSub from '@/components/layout/sub/subpage_header_support.vue';
  import Banner2 from '@/components/pages/contact/contact_body_main.vue';
  import BodyMainBanner9 from '@/components/pages/index/body_main_banner9.vue';
  import Footer from '@/components/layout/footer/footer_default.vue';
  import { ref, onMounted, onUnmounted } from 'vue';
  
  export default {
    components: {
      Header,
      HeaderSub,
      Banner2,
      BodyMainBanner9,
      Footer,
    },
    setup() {
      const isHovering = ref(false);
      const backgroundColor = ref('transparent');
      const imgHeight = ref(0); // 이미지의 높이를 저장할 변수

      const componentStyles = ref({
        HeaderBackgroundColor: '#06384F',
        HeaderSubBackgroundColor: '#262626',
        TitleName: 'Contact US'
      });
  
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // 스크롤이 0 이상일 때 배경색을 white로 변경
        if (scrollPosition > 0) {
          backgroundColor.value = 'white';
        } else {
          backgroundColor.value = 'transparent';
        }
      };
  
      const calculateImgHeight = () => {
        imgHeight.value = window.innerHeight + 66;
      };
  
      onMounted(() => {
        calculateImgHeight(); // 컴포넌트가 마운트되면 이미지 높이를 계산
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', calculateImgHeight); // 윈도우 크기가 변경될 때마다 이미지 높이를 다시 계산
      });
  
      onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', calculateImgHeight);
      });
  
      return { isHovering, backgroundColor, imgHeight, componentStyles };
    }
  };
  </script>
  
  <style scoped>
  .text-overlay {
    position: absolute;
    top: 120px;
    left: 120px;
    color: white;
    font-size: 45px;
    font-weight: 900;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  </style>
  