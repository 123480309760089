<template>
    <div>
      <TopLayout :is-hovering="isHovering" :background-color="backgroundColor" :user-permission="user_permission"/>
      <HeaderSub :user-permission="user_permission" :componentStyles="componentStyles"/>
      <Banner2 :user-permission="user_permission"/>
      
    </div>
  </template>
  
  <script>
  import TopLayout from '@/components/layout/header/header_default.vue';
  import HeaderSub from '@/components/layout/sub/subpage_header_support.vue';
  import Banner2 from '@/components/pages/estimate/estimate_body_main.vue';
  import { ref, onMounted, onUnmounted } from 'vue';
  import axios from 'axios';
  
  export default {
    components: {
      TopLayout,
      HeaderSub,
      Banner2,
    },
    setup() {
      const isHovering = ref(false);
      const backgroundColor = ref('transparent');
      const imgHeight = ref(0);
      const user_permission = ref(null);

      const componentStyles = ref({
        HeaderBackgroundColor: '#06384F',
        HeaderSubBackgroundColor: '#262626',
        TitleName: '온라인 견적'
      });
  
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // 스크롤이 0 이상일 때 배경색을 white로 변경
        if (scrollPosition > 0) {
          backgroundColor.value = 'white';
        } else {
          backgroundColor.value = 'transparent';
        }
      };
  
      const calculateImgHeight = () => {
        imgHeight.value = window.innerHeight + 66;
      };
  
      const fetchUserPermission = () => {
        const payload = {
        'uuid_iv' : localStorage.getItem('uuid_iv'),
        'vft_uuid_iv' : localStorage.getItem('vft_uuid_iv'),
        };
        axios.post(`https://api.samjinnetworks.com/api/web/menu/user/controller`, payload)
        .then(response => {
            user_permission.value = response.data.selectData[0].user_permission;
        })
        .catch(error => {
            console.error('오류:', error);
            // 오류 메시지 처리
        });
      };
  
      onMounted(() => {
        calculateImgHeight(); // 컴포넌트가 마운트되면 이미지 높이를 계산
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', calculateImgHeight); // 윈도우 크기가 변경될 때마다 이미지 높이를 다시 계산
        if(localStorage.getItem('uuid_iv') && localStorage.getItem('vft_uuid_iv')) {
          fetchUserPermission();
        }
      });
  
      onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', calculateImgHeight);
      });
  
      return { 
        isHovering, backgroundColor, imgHeight, user_permission, componentStyles,
        fetchUserPermission
      };
    }
  };
  </script>
  
  <style scoped>
  .text-overlay {
    position: absolute;
    top: 120px;
    left: 120px;
    color: white;
    font-size: 45px;
    font-weight: 900;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  </style>
  