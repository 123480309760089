import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      uuidIv: null,
      pcNo: null,
      pcFilter: [],
      items: [],
    };
  },
  mutations: {
    setUuidIv(state, uuidIv) {
      state.uuidIv = uuidIv;
    },
    setPcNo(state, pcNo) {
      state.pcNo = pcNo;
    },
    setPcFilter(state, pcFilter) {
      state.pcFilter = pcFilter;
    },
    setItems(state, items) {
      state.items = items;
    },
  },
  actions: {
    setUuidIv({ commit }, uuidIv) {
      commit('setUuidIv', uuidIv);
    },
    setPcNo({ commit }, pcNo) {
      commit('setPcNo', pcNo);
    },
    setPcFilter({ commit }, pcFilter) {
      commit('setPcFilter', pcFilter);
    },
    setItems({ commit }, items) {
      commit('setItems', items);
    },
  },
  getters: {
    getUuidIv: state => state.uuidIv,
    getPcNo: state => state.pcNo,
    getPcFilter: state => state.pcFilter,
    getItems: state => state.items,
  },
});
