<template>
    <div class="container">
      <v-card
        class="card"
        :style="{
          width: isMobile ? '100%' : '70%',
          padding: isMobile ? '10px' : '0px',
          height: isMobile ? '700px' : '460px',
          borderRadius: isMobile ? '0px' : '0px',
          marginTop: isMobile ? '0px' : '0px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center'
        }"
        style="
        background-color: #FFFFFF;
        "
        elevation="0"
      >

      <div class="text-section" style="flex: 1;">
          <div
          :style="{
            fontSize: isMobile ? '30px' : '22px',
            fontWeight: isMobile ? '900' : '900',
          }">
            넥스트클라우드 어시스턴트
          </div>
  
            <div
              :style="{
                fontSize: isMobile ? '15px' : '14px',
                fontWeight: isMobile ? '100' : '500',
                paddingTop: '20px',
              }"
            >
              Nextcloud Hub 협업 플랫폼에 내장된 최초의 로컬 AI 비서. 앱 전반에 통합되어 콘텐츠 생성, 데이터 관련 질문 응답, 이메일 요약, 번역 등 다양한 기능을 제공합니다.
            </div><br>
            <div
              :style="{
                fontSize: isMobile ? '14px' : '15px',
                fontWeight: isMobile ? '100' : '500',
              }"
            >
              데이터 유출을 방지하면서 생산성을 높이세요.
            </div>
            
        </div>
        <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <img src="/images/nextcloud/nextcloud-assistant-preview.png" :width="isMobile ? '700' : '700'">
        </div>
  
      </v-card>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  import { ref, computed, onMounted } from 'vue';
  
  export default {
    setup() {
      const router = useRouter();
      const isMobile = ref(false);
  
      const checkIsMobile = () => {
        isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
      };
  
      const goToestimate = () => {
        router.push('/estimate');
      }
  
      onMounted(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
      });
  
      return {
        goToestimate,
        isMobile: computed(() => isMobile.value),
        isDesktop: computed(() => !isMobile.value),
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
  }
  
  
  
  /* Styles for mobile */
  @media screen and (max-width: 768px) {
    .text-section {
      padding: 0px 20px 0 20px;
    }
  }
  </style>
  