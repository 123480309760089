<!-- login/auth_index.vue -->
<template>
    <div id="app">
      <div class="auth-container">
        <h1>ADMIN LOGIN</h1>
        <p class="subtitle">Business Management System</p>
        <form @submit.prevent="onSubmit">
          <div class="input-wrapper">
            <input id="username" v-model="username" placeholder="ID" required>
          </div>
          <div class="input-wrapper">
            <input id="password" type="password" v-model="password" placeholder="PASSWORD" required>
          </div>
          <div class="submit-wrapper">
            <button type="submit">로그인</button>
          </div>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        </form>
      </div>
    </div>
</template>
    
  <script>
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    import { ref } from 'vue';
    
    export default {
      name: 'AuthIndex',
      setup() {
  
        const router = useRouter();
        const username = ref('');
        const password = ref('');
        const errorMessage = ref('');
        
    
        const onSubmit = async () => {
          try {
            const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
            const response = await axios.post(`${request}/thirteen/encryption/select/user`, {
              login_id: username.value,
              login_password: password.value,
            });
   
            localStorage.setItem('vft_uuid_iv', response.data.bcrypt_uuid);
            localStorage.setItem('uuid_iv', response.data.uuid);
    
            // 대시보드나 메인 페이지로 리다이렉트
            router.push({ name: 'EstimateIndex' });
          } catch (error) {
            errorMessage.value = '아이디와 비밀번호를 확인하세요';
            console.error('로그인 오류:', error);
          }
        };
    
        return {
          username, password, errorMessage,
          onSubmit
        };
      },
    };
  </script>  
    
  <style scoped>
  #app {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100vw;
    height: 100vh;
    animation: colorChange 10s infinite;
  }

  .auth-container {
    background-color: #EBECEF;
    border-radius: 20px;
    width: 100%;
    max-width: 400px;
    height: 80vh;
    max-height: 550px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  
  @keyframes colorChange {
    0%, 100% { background-color: #164062; }
    50% { background-color: black; }
  }

  @media (max-width: 768px) {
    .auth-container {
      height: 80%;
    }
  }
  
  h1 {
    margin-bottom: 0.5em;
  }
  
  .subtitle {
    margin-bottom: 2em;
    color: #333;
    font-size: 0.9em;
  }
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .input-wrapper {
    margin-bottom: 1em;
    width: 100%;
  }
  
  .input-wrapper input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #000000;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1em;
    background-color: #FFFFFF;
  }
  
  .submit-wrapper {
    width: 100%;
  }
  
  .submit-wrapper button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: black;
    color: white;
    font-size: 1em;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #333;
  }
  </style>
  