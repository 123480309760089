<template>
  <div class="container">
    <v-card
      class="card"
      :style="{
        width: isMobile ? '100%' : '80%',
        padding: isMobile ? '10px' : '0px',
        height: isMobile ? '700px' : '500px',
        borderRadius: isMobile ? '0px' : '40px',
        marginTop: isMobile ? '20px' : '40px',
        marginBottom: isMobile ? '20px' : '40px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
      }"
      style="
      background-color: #F4F4F7;
      "
      elevation="0"
    >
      <div class="text-section" style="flex: 1; margin-bottom: -30px;"
        v-if="isMobile"
        :style="{
          paddingLeft: isMobile ? '0px' : '10px',
        }"
      >
        <div
        :style="{
          fontSize: isMobile ? '30px' : '55px',
          fontWeight: isMobile ? '900' : '900',
        }">
          찾아오시는길
        </div>
        <br>
      </div>

      <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12696.61274786722!2d127.0484628!3d37.2915031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5b35e7a9ad4b%3A0x77d879fd70716097!2z7IK87KeE64Sk7Yq47JuN7Iqk!5e0!3m2!1sko!2skr!4v1714808685737!5m2!1sko!2skr" 
            width="100%"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            :style="{
              height: isMobile ? '300px' : '800px',
              marginBottom: isMobile ? '-35px' : '0px',
            }"
          ></iframe>
      </div>

      <div class="text-section" style="flex: 1;"
        :style="{
          paddingLeft: isMobile ? '0px' : '10px',
        }"
      >
        <div
        v-if="!isMobile"
        :style="{
          fontSize: isMobile ? '30px' : '55px',
          fontWeight: isMobile ? '900' : '900',
        }">
          찾아오시는길
        </div>
        <br>
        <div
          :style="{
            fontSize: isMobile ? '15px' : '15px',
            fontWeight: isMobile ? '100' : '500',
          }"
        >
          경기도 수원시 영통구 센트럴타운로 107 (광교푸르지오 월드마크, 월드스퀘어) 204동 1층 1-047호
        </div><br>

        <div :style="{
          width: isMobile ? '100%' : '80%',
        }">
          <v-expansion-panels
            v-model="panel"
            :readonly="readonly"
          >
            <v-expansion-panel disabled>
              <v-expansion-panel-title>대중교통 이용시</v-expansion-panel-title>
              <v-expansion-panel-text>
                광교중앙역 3번출구 에서 나와 400m 직진하여 보이는 월드푸르지오마크 상가에 위치하고,
                월드푸르지오마크 상가 1번게이트 인근에 위치하고 있습니다
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

    </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
  data: () => ({
      panel: [0],
      readonly: false,
    }),
  setup() {
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return {
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value)
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}



/* Styles for mobile */
@media screen and (max-width: 768px) {
  .text-section {
    padding: 20px 20px 0 20px;
  }
}
</style>
