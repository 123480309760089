<template>
  <v-window>
    <template v-slot:default="{ scroll }">
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-app-bar
            v-bind="props"
            :color="isHovering || scroll > 0 ? 'white' : backgroundColor"
            elevation="0"
            data-scroll="0"
          >
            <template v-slot:prepend>
            </template>
            <v-app-bar-title>
              <img
                :src="isHovering || backgroundColor === 'white' ? 'https://cdn.samjinnetworks.com/images/web/main_logo.png' : 'https://cdn.samjinnetworks.com/images/web/main_logo_white.png'"
                max-width="246"
                height="17"
                @click="goToMainRouter"
                style="cursor: pointer;"
              />
            </v-app-bar-title>

            <v-menu
              open-on-hover
              offset-y
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-if="!isMobile"
                  :style="{
                    fontWeight: '900',
                    color: isHovering || backgroundColor === 'white' ? 'black' : 'white'
                  }"
                  v-bind="props"
                >
                  클라우드/서버임대
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="handleMenuItemClick('ubuntu')">
                  VPS - LinuxUbuntu
                </v-list-item>
                <v-list-item @click="handleMenuItemClick('nextcloud')">
                  VPS - Nextcloud
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu
              open-on-hover
              offset-y
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-if="!isMobile"
                  :style="{
                    fontWeight: '900',
                    color: isHovering || backgroundColor === 'white' ? 'black' : 'white'
                  }"
                  v-bind="props"
                >
                  컴퓨터 및 하드웨어
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="handleMenuItemClick('온라인견적서')">
                  온라인견적서
                </v-list-item>
                <v-list-item @click="handleMenuItemClick('추천조립PC')">
                  추천조립PC
                </v-list-item>
                <v-list-item @click="handleMenuItemClick('기업용렌탈')">
                  기업렌탈서비스
                </v-list-item>
                <v-list-item @click="handleMenuItemClick('중고매입신청')">
                  중고매입서비스
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu
              open-on-hover
              offset-y
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-if="!isMobile"
                  :style="{
                    fontWeight: '900',
                    color: isHovering || backgroundColor === 'white' ? 'black' : 'white'
                  }"
                  v-bind="props"
                >
                  고객센터
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="handleMenuItemClick('Contact Us')">
                  온라인문의
                </v-list-item>
                <v-list-item @click="handleMenuItemClick('원격지원')">
                  원격지원
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu
              v-if="isMobile"
              transition="slide-x-transition"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                >
                  <v-icon :color="isHovering || backgroundColor === 'white' ? 'black' : 'white'">mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in Business_items"
                  :key="i"
                  @click="handleMenuItemClick(item.title)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          </v-app-bar>
        </template>
      </v-hover>
      <v-spacer></v-spacer>
    </template>
  </v-window>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed, onMounted } from 'vue';

export default {
  props: {
    backgroundColor: String, // backgroundColor prop 추가
  },
  setup() {
    const router = useRouter();
    const isMobile = ref(false);
    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    };

    const Business_items = [
      { title: '온라인견적서' },
      { title: '추천조립PC' },
      { title: '기업용렌탈' },
      { title: '중고매입신청' },
      { title: 'Contact Us' },
    ];

    const handleMenuItemClick = (item) => {
      if (item.title || item === 'Contact Us') {
        router.push('/contact');
      } else if (item.title || item === '온라인견적서') {
        router.push('/estimate');
      } else if (item.title || item === '추천조립PC') {
        router.push('/recommend');
      } else if (item.title || item === '기업용렌탈') {
        router.push('/rental');
      } else if (item.title || item === '중고매입신청') {
        router.push('/purchase');
      } else if (item.title || item === '원격지원') {
        window.location.href = 'https://github.com/rustdesk/rustdesk/releases/download/1.3.0/rustdesk-1.3.0-x86_64.exe';
      } else if (item.title || item === 'nextcloud') {
        router.push('/nextcloud');
      } else if (item.title || item === 'ubuntu') {
        router.push('/ubuntu');
      }
    };

    const goToMainRouter = () => {
      router.push('/');
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
    });

    return { 
      Business_items, handleMenuItemClick, goToMainRouter,
      isMobile: computed(() => isMobile.value),
      isDesktop: computed(() => !isMobile.value),
    };
  }

};
</script>
