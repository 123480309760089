<template>
    <v-card
        :height="imgHeight"
        :style="{
            overflowY: 'auto' 
        }"
    >
        <v-overlay :model-value="loading" class="align-center justify-center">
            <p style="color: white; font-size: 22px; text-align: center;">
                잠시만 기다려주세요
            </p>
            <br>
            <v-progress-circular color="primary" size="200" indeterminate></v-progress-circular>
        </v-overlay>
        <v-data-table
            :items="filteredItems"
            :headers="headers"
            style="overflow-y: auto; height: 100%;"
        >
            <template v-slot:headers>
            </template>
            <template v-slot:[`item.buy_link1`]="{ item }">

                <v-dialog
                    width="auto" height="auto" style="overflow: auto;"
                    v-model="item_detail_checked"
                >
                    <v-card>
                        <itemDetails
                            :selectedItems="recommend_pc_item"
                            :selectedDetailItems="itemsDetail"
                            :detail_image="item.item_image_link"
                            :pc_no="item.pc_no"
                            :pc_nm="item.pc_nm"
                            :pc_sub_nm="item.pc_sub_nm"
                            :thumnailThemeColor="thumnailThemeColor"
                        />
                        <v-card-actions>
                            <span style="font-size: 22px; font-weight: 800;">{{ formatPrice(selectPCSaleprice) }}</span>원
                            <v-spacer></v-spacer>
                            
                            <v-btn
                                text="구매하기"
                                @click="goToStore()"
                            ></v-btn>
                            <v-btn
                                text="닫기"
                                @click="item_detail_checked = false"
                            ></v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
                
                <v-hover v-slot="{ isHovering, props }">
                    <v-card
                        :class="{ 'on-hover': isHovering }"
                        :elevation="isHovering ? 12 : 2"
                            v-bind="props"
                            style="position: relative; display: inline-block; cursor: pointer;"
                        @click="item_detail_checkedToggle(item)"
                    >
                    <div style="position: relative; width: 300px; height: 300px;">
                        <img :src="item.buy_link1" width="300" height="300">
                        <div 
                            v-if="isHovering && !isMobile" 
                            :style="{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: '23px',
                                pointerEvents: 'none',
                            }"
                        > + 더보기 </div>
                    </div>
                    </v-card>
                </v-hover>
            </template>
            <template v-slot:[`item.pc_no`]="{ item }">
                <h2 class="text-h5 font-weight-black text-orange">PS-TCMBSJ{{ item.pc_no }}</h2>
                <br>
                <h2 class="text-h7 font-weight-black text-black">{{ item.pc_nm }}</h2>
                
                {{ item.cpu_series }} [{{ item.pc_sub_nm }}]<br>
                <span v-if="item.pc_os.toLowerCase().includes('ubuntu')">
                    <img src="https://assets.ubuntu.com/v1/ff6a9a38-ubuntu-logo-2022.svg" width="150">
                </span>
                <span v-else-if="item.pc_os.toLowerCase().includes('window')">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Windows_11_logo.svg/799px-Windows_11_logo.svg.png" width="150">
                </span>
                <span v-else>
                    Free DOS(운영체제 미포함)
                </span>

                <br>
                <v-row>
                    <v-spacer></v-spacer>
                    <h2 class="text-h7 font-weight-black text-black">
                        {{ formatPrice(item.sale_price) }}원
                    </h2>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!isMobile"
                        color="primary"
                        append-icon="mdi-chevron-double-right"
                        @click="searchDetail(item.pc_no)"
                    >
                        구성장치확인
                        <template v-slot:append>
                            <v-icon color="#FFFFFF"></v-icon>
                        </template>
                    </v-btn>
                    <v-btn
                        v-if="isMobile"
                        color="primary"
                        append-icon="mdi-chevron-double-right"
                        @click="item_detail_checkedToggle(item)"
                    >
                        구매하기
                        <template v-slot:append>
                            <v-icon color="#FFFFFF"></v-icon>
                        </template>
                    </v-btn>
                </v-row>
            </template>

        </v-data-table>
    </v-card>

</template>
<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { fetchGetData } from '../../common/JSfunction/usedFunction.js';
import itemDetails from './recommend_pc_item_details';

export default {
    components: {
        itemDetails,
    },
    setup() {
        const store = useStore();
        const pcFilter = computed(() => store.getters.getPcFilter);

        const items = ref([]);
        const loading = ref(true);
        const isMobile = ref(false);

        const imgHeight = ref(0);
        const calculateImgHeight = () => {
            if(isMobile.value) {
                imgHeight.value = window.innerHeight - 65 - 50;
            } else if (!isMobile.value) {
                imgHeight.value = window.innerHeight - 65 - 50;
            } else {
                imgHeight.value = window.innerHeight
            }
        };

        const headers = ref([
            { title: '이미지', value: 'buy_link1'},
            { title: '이미지', value: 'pc_no'},
        ]);

        const fetchData = async () => {
            try {
                const apiPath = '/menu/item/ItemRecommendPC/manage/select';
                const queryParams = new URLSearchParams({
                    row_state: 0,
                    smartstore_item_cd: '',
                    buy_link1: ''
                });
                const data = await fetchGetData(apiPath, queryParams.toString());
                items.value = data.map(item => ({
                    ...item,

                    id: item.pc_no,
                }));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const itemsDetail = ref([]);
        const fetchDataDetail = async(pc_no) => {
            itemsDetail.value = [];
            try {
                const apiPath = '/menu/item/ItemRecommendPC/detail/manage/select';
                const data = await fetchGetData(apiPath, `pc_no=` + pc_no);
                itemsDetail.value = data.map(item => ({
                    ...item,
                    item_category: item.item_category,
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
            

        const filteredItems = computed(() => {
            setLoading();

            // 1. 필터링
            const itemsAfterFiltering = filterItems(items.value, pcFilter.value);
            // 2. 정렬
            const itemsAfterSorting = sortItems(itemsAfterFiltering, pcFilter.value);

            return itemsAfterSorting;
        });

        const filterItems = (items, filters) => {
            const filteredItems = items.filter(item => {
                return filters.every(filter => {
                    if (filter.menu_code === 'processor' && item.cpu_series === filter.menu_name) {
                        return true;
                    }

                    if (filter.menu_code === 'priceOrderby') {
                        const price = Number(item.sale_price);
                        if (filter.menu_name === '50만원미만') {
                            return price < 500000;
                        } else if (filter.menu_name === '50만원~100만원') {
                            return price >= 500000 && price <= 1000000;
                        } else if (filter.menu_name === '100만원~150만원') {
                            return price >= 1000000 && price <= 1500000;
                        } else if (filter.menu_name === '150만원~200만원') {
                            return price >= 1500000 && price <= 2000000;
                        } else if (filter.menu_name === '200만원~300만원') {
                            return price >= 2000000 && price <= 3000000;
                        } else if (filter.menu_name === '300만원이상') {
                            return price >= 3000000;
                        }
                    }

                    if (filter.menu_code === 'ramType' && item.pc_sub_nm.includes(filter.menu_name)) {
                        return true;
                    }

                    if (filter.menu_code === 'ramGB' && item.pc_sub_nm.includes(filter.menu_name)) {
                        return true;
                    }

                    if (filter.menu_code === 'graphicCard') {
                        if (item.pc_sub_nm.includes(filter.menu_name)) {
                            return true;
                        }
                        if (filter.menu_name === '내장그래픽' && !item.pc_sub_nm.includes('GTX') && !item.pc_sub_nm.includes('RTX')) {
                            return true;
                        }
                    }

                    if (filter.menu_code === 'SSD' && item.pc_sub_nm.includes(filter.menu_name)) {
                        return true;
                    }

                    if (filter.menu_code === 'HDD' && item.pc_sub_nm.includes(filter.menu_name)) {
                        return true;
                    }

                    if (filter.menu_code === 'os') {
                        if (filter.menu_name === 'Free DOS' && item.pc_os === '') {
                            return true;
                        }
                        return item.pc_os === filter.menu_name;
                    }
                    
                    if (filter.menu_code === 'Orderby') {
                        return item;
                    }

                    return false;
                });
            });

            return filteredItems;
        };


        const sortItems = (items, filters) => {

            // Orderby 필터를 찾아서 정렬을 수행합니다.
            const sortFilter = filters.find(filter => filter.menu_code === 'Orderby');
            if (!sortFilter) return items; // Orderby 필터가 없으면 정렬을 하지 않습니다.

            // 원본 배열을 변경하지 않도록 복사
            let sortedItems = [...items];

            if (sortFilter.menu_name === '최신등록순') {
                sortedItems.sort((a, b) => Number(b.pc_no) - Number(a.pc_no));
            } else if (sortFilter.menu_name === '낮은가격순') {
                sortedItems.sort((a, b) => {
                    // 데이터 타입 확인 및 변환
                    const priceA = Number(a.sale_price);
                    const priceB = Number(b.sale_price);
                    return priceA - priceB;
                });
            } else if (sortFilter.menu_name === '높은가격순') {
                sortedItems.sort((a, b) => {
                    // 데이터 타입 확인 및 변환
                    const priceA = Number(a.sale_price);
                    const priceB = Number(b.sale_price);
                    return priceB - priceA;
                });
            }

            return sortedItems;
        };






        const setLoading = () => {
            loading.value = true;
            setTimeout(() => {
                loading.value = false; // 2초 후에 loading 값을 false로 설정
            }, 700);
        };

        const formatPrice = (price) => {
        // price를 숫자로 파싱한 후, toLocaleString을 사용하여 한국 원 단위 포맷으로 변경
        const formattedPrice = Number(price).toLocaleString('ko-KR');
        return `${formattedPrice}`; // '원' 문자열을 추가하여 반환
        };

        const searchDetail = (pc_no) => {
            store.dispatch('setPcNo', pc_no)
        };

        const item_detail_checked = ref(false);
        const recommend_pc_item = ref([]);
        const selectPCSaleprice = ref(0);
        const item_detail_checkedToggle = (item) => {
            fetchDataDetail(item.pc_no);

            if(isMobile.value) {
                goToStore(item.smartstore_item_cd);
            } else {
                recommend_pc_item.value = item;
                selectPCSaleprice.value = item.sale_price || 0;
                item_detail_checked.value = !item_detail_checked.value
            }
        };

        const goToStore = async (param_item_cd) => {
            let param ;
            if(param_item_cd) {
                param = param_item_cd;
            } else {
                param = itemsDetail.value[0].smartstore_item_cd || '';
            }
            const url = `https://smartstore.naver.com/blackitem/products/${param}`;
            window.open(url, '_blank');
        }


        const checkIsMobile = () => {
            isMobile.value = window.innerWidth <= 768;
        };

        onMounted(() => {
            checkIsMobile();
            fetchData();
            calculateImgHeight();
        });

        return{
            item_detail_checked, item_detail_checkedToggle, recommend_pc_item,
            items, loading, headers, imgHeight, setLoading,
            fetchData, formatPrice, searchDetail, filteredItems, filterItems, sortItems,
            itemsDetail, fetchDataDetail, goToStore, selectPCSaleprice,
            isMobile: computed(() => isMobile.value),
            checkIsMobile, 
        }
    }
}
</script>