<template>
    <div class="container">
      <v-card
        class="card"
        :style="{
          width: isMobile ? '100%' : '70%',
          padding: isMobile ? '10px' : '0px',
          height: isMobile ? '700px' : '460px',
          borderRadius: isMobile ? '0px' : '0px',
          marginTop: isMobile ? '0px' : '0px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center'
        }"
        style="
        background-color: #FFFFFF;
        "
        elevation="0"
      >

      <div class="text-section" style="flex: 1;">
          <div
          :style="{
            fontSize: isMobile ? '30px' : '22px',
            fontWeight: isMobile ? '900' : '900',
          }">
            Ubuntu Server
          </div>

            <div
              :style="{
                fontSize: isMobile ? '15px' : '15px',
                fontWeight: isMobile ? '100' : '500',
                paddingTop: '20px',
              }"
            >
              21세기에는 전 세계 사람들을 비즈니스, 사회적, 그리고 오락 서비스의 방대한 네트워크로 연결하는 전례 없는 규모의 컴퓨팅이 이루어질 것입니다.
            </div><br>
            <div
              :style="{
                fontSize: isMobile ? '14px' : '15px',
                fontWeight: isMobile ? '100' : '500',
              }"
            >
              이런 대규모 컴퓨팅 용량을 제공하는 것은 오늘날의 주요 인프라 과제입니다. 이 과제는 새로운 초고밀도 하드웨어와 네이티브 확장성을 위해 설계된 소프트웨어의 결합으로 해결되고 있습니다.
            </div>
            
        </div>
        <div class="image-section" style="flex: 1; display: flex; justify-content: center;">
          <img src="/images/ubuntu/Canonical-server-datacenter.png" :width="isMobile ? '700' : '500'">
        </div>
  
      </v-card>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  import { ref, computed, onMounted } from 'vue';
  
  export default {
    setup() {
      const router = useRouter();
      const isMobile = ref(false);
  
      const checkIsMobile = () => {
        isMobile.value = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
      };
  
      const goToestimate = () => {
        router.push('/estimate');
      }
  
      onMounted(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
      });
  
      return {
        goToestimate,
        isMobile: computed(() => isMobile.value),
        isDesktop: computed(() => !isMobile.value),
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: center;
  }
  
  
  
  /* Styles for mobile */
  @media screen and (max-width: 768px) {
    .text-section {
      padding: 0px 20px 0 20px;
    }
  }
  </style>
  