<template>
  <v-img 
    :src="`https://cdn.samjinnetworks.com/images/web/header2.jpg`" 
    width="100%"
    :height="imgHeight"
    cover
    style="margin-top: -65px;"
  >
  <div>
    <banner1Threejs />
  </div>


  </v-img>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import banner1Threejs from './body_main_banner1_threejs.vue';

export default {
  components: {
    banner1Threejs,
  },
  setup() {
    const imgHeight = ref(0);

    const calculateImgHeight = () => {
      imgHeight.value = window.innerHeight + 1;
    };

    onMounted(() => {
      calculateImgHeight();
      window.addEventListener('resize', calculateImgHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', calculateImgHeight);
    });

    return { imgHeight };
  }
};
</script>

<style scoped>
.text-overlay {
  z-index: 1; /* Ensure the overlay is above the image */
}
</style>
