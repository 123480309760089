<template>
      <v-overlay :model-value="loading" class="align-center justify-center">
        <p style="color: white; font-size: 22px; text-align: center;">
            잠시만 기다려주세요
        </p>
        <br>
        <v-progress-circular color="primary" size="200" indeterminate></v-progress-circular>
    </v-overlay>
    <v-card
      elevation="5"
      style="padding: 30px; height: 830px;"
    >
        <v-data-table-virtual
            v-model="selected"
            :headers="cartheaders"
            :items="cartItems"
            item-key="id"
            id="items-data-table"
            class="styled-table"
            :items-per-page="40"
            style="overflow-y: auto;"
            :height="rightCardHeight"
            no-data-text="장바구니가 비어 있습니다."
        >

        <template v-slot:headers>
          </template>
          <template v-slot:[`item.item_image_link`]="{ item }">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
            <img :src="item.item_image_link" alt="Product Image" width="80" height="80"
              style="cursor: pointer;"
            />
            </div>
          </template>
          <template v-slot:[`item.item_nm`]="{ item }">
            <div
              style="
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
              "
              :style="{
                width: isMobile ? '100%' : '',
              }"
            >

              <div
              v-if="!isSmallScreen"
                style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;
                width: 100%;
                "
              >
                <p style="font-size: 12px;"> {{ item.item_category }}<br>{{ item.item_nm }}</p>

                <div style="display: flex; align-items: center;">
                  <div
                    style="border: 1px solid gray; width: 20px; text-align: center;"
                  >
                    <v-icon size="x-small" icon="mdi-minus"
                    style="
                      padding: 0 10px 0 10px;
                      cursor: pointer;
                    "
                    @click="cart_item_minus_qty(item)"
                    ></v-icon>
                  </div>
                  <div
                    style="border: 1px solid gray; width: 30px; text-align: center;"
                  >
                    {{ item.cart_qty }}
                  </div>
                  <div
                    style="border: 1px solid gray; width: 20px; text-align: center;"
                  >
                    <v-icon size="x-small" icon="mdi-plus"
                    style="
                      padding: 0 10px 0 10px;
                      cursor: pointer;
                    "
                    @click="cart_item_plus_qty(item)"
                    ></v-icon>
                  </div>
                  

                  <v-spacer></v-spacer>
                  <b>월 {{ formatPrice(item.sum_price) }}</b>원
                  <v-icon size="x-small" icon="mdi-close"
                    style="
                      padding: 0 10px 0 10px;
                      cursor: pointer;
                    "
                    @click="cancel_cart_item(item)"
                  ></v-icon>
                </div>
              </div>
              <div
              v-else
              >
              <div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
                {{ item.item_nm }}
              </div>
                <div style="align-items: center;">
                  <b>{{ formatPrice(item.sale_price) }}원</b>  
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.sale_price`]="{ item }">
            <div v-if="!isMobile">
              <div>{{ formatPrice(item.sale_price) }}원 
                <v-number-input
                :reverse="false"
                controlVariant="default"
                label=""
                :hideInput="false"
                :inset="false"
                variant="outlined"
              ></v-number-input>
              </div>
            </div>
          </template>

          <template v-slot:bottom>
          </template>

        </v-data-table-virtual>

      <v-spacer></v-spacer>

      
      <v-row>
          <span>
              월 <span style="font-size: 22px; font-weight: 800;">{{ formatPrice(cartTotalPrice) }}</span>원
          </span>
          <v-spacer></v-spacer>
            <v-btn variant="outlined"
              style="
              border-color: #C4C4C4;
              color: black;
              "
              @click="open_cust_info_input_dialog"
            >
              상담신청
            </v-btn>

      </v-row>
    </v-card>


    <v-dialog
        width="800px"
        v-model="cust_info_input_dialog"
    >
        <v-card
        prepend-icon="mdi-account"
        title="렌탈 상담신청"
        >

        <v-card-text style="color: red;">
          본 서비스는 B2B 서비스로 개인고객은 신청하실 수 없습니다
        </v-card-text>

        <v-data-table
            v-model="selected"
            :headers="cartheaders"
            :items="cartItems"
            item-key="id"
            id="items-data-table"
            class="styled-table"
            :items-per-page="40"
            style="overflow-y: auto;"
            height="max-content"
            no-data-text="장바구니가 비어 있습니다."
        >

        <template v-slot:headers>
          </template>
          <template v-slot:[`item.item_image_link`]="{ item }">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
            <img :src="item.item_image_link" alt="Product Image" width="80" height="80"
              style="cursor: pointer;"
            />
            </div>
          </template>
          <template v-slot:[`item.item_nm`]="{ item }">
            <div
              style="
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
              "
              :style="{
                width: isMobile ? '100%' : '',
              }"
            >

              <div
              v-if="!isSmallScreen"
                style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;
                width: 100%;
                "
              >
                <p style="font-size: 12px;"> {{ item.item_category }}<br>{{ item.item_nm }}</p>

                <div style="display: flex; align-items: center;">

                  <div
                    style="border: 1px solid gray; width: 30px; text-align: center;"
                  >
                    {{ item.cart_qty }} 
                  </div>&nbsp;EA

                  

                  <v-spacer></v-spacer>
                  <b>월 {{ formatPrice(item.sum_price) }}</b>원
                </div>
              </div>
              <div
              v-else
              >
              <div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
                {{ item.item_nm }}
              </div>
                <div style="align-items: center;">
                  <b>{{ formatPrice(item.sale_price) }}원</b>  
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.sale_price`]="{ item }">
            <div v-if="!isMobile">
              <div>{{ formatPrice(item.sale_price) }}원 
                <v-number-input
                :reverse="false"
                controlVariant="default"
                label=""
                :hideInput="false"
                :inset="false"
                variant="outlined"
              ></v-number-input>
              </div>
            </div>
          </template>

          <template v-slot:bottom>
          </template>

        </v-data-table>

        <v-card-text>
            <v-row dense>
              <v-row>
                <v-col cols="6" md="6" sm="6">
                    <v-text-field
                    v-model="insertCustNm"
                    label="업체명*"
                    solo-inverted
                    hide-details
                    clearable
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="6" md="6" sm="6">
                    <v-text-field
                    v-model="insertMemberNm"
                    label="담당자명*"
                    solo-inverted
                    hide-details
                    clearable
                    >
                    </v-text-field>
                </v-col>
              </v-row>
            </v-row>
            <v-row dense>
            <v-col cols="12" md="4" sm="6">
                <v-select
                label="전화번호*"
                v-model="insertCustNumberinput1"
                :items="NumberAreaCode"
                ></v-select>
            </v-col>
            <v-col cols="12" md="4" sm="6">
                <v-text-field
                v-model="insertCustNumberinput2"
                solo-inverted
                hide-details
                clearable
                @input="limitInput"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6">
                <v-text-field
                v-model="insertCustNumberinput3"
                solo-inverted
                hide-details
                clearable
                @input="limitInput"
                >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="6">
                <v-text-field
                v-model="insertRentalPeriod"
                label="렌탈기간"
                solo-inverted
                hide-details
                clearable
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="6">
                <v-text-field
                v-model="insertCompanyAdress"
                label="사업장위치"
                solo-inverted
                hide-details
                clearable
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="6">
                <v-text-field
                v-model="insertQustion"
                label="상담내용"
                solo-inverted
                hide-details
                clearable
                >
                </v-text-field>
            </v-col>

            </v-row>
            <v-row dense>

            </v-row>
        </v-card-text>

        <v-card-text tyle="display: flex; justify-content: flex-end;">

          <v-container fluid>
            <v-checkbox v-model="contactCheck">
              <template v-slot:label>
                <div>
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span
                        v-bind="props"
                        @click="policy_personal_informationToggle"
                        style="cursor: pointer; text-decoration: underline; color: blue;"
                      >
                        개인정보 수집 및 이용
                      </span>
                    </template>
                    개인정보취급방침
                  </v-tooltip>
                  에 동의합니다
                </div>
              </template>
            </v-checkbox>
          </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="orange"
            @click="open_estimate_dialog"
            style="
            font-weight: 900;
            "
        >견적서 출력</v-btn>
        <v-btn
            color="orange"
            @click="sendEmail()"
            style="
            font-weight: 900;
            "
        >상담등록</v-btn>

        </v-card-actions>

        </v-card>
    </v-dialog>
  <v-dialog
        width="max-content" height="max-content"
        v-model="estimate_dialog"
  >
    <EstimateManagementPrint 
      :selectedRowData="{
        insertCustNm: insertCustNm,
        insertCustNumber: insertCustNumberinput1+'-'+insertCustNumberinput2+'-'+insertCustNumberinput3,
        cartItems: cartItems
      }" />
  </v-dialog>
  <v-dialog
    width="max-content" height="max-content"
    v-model="policy_personal_information"
  >
    <v-card>
      <PersonalInformation />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="surface-variant"
          text="닫기"
          variant="flat"
          @click="policy_personal_informationToggle"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    
    <v-snackbar
      v-model="snackbarOn"
      :timeout="500"
      :color="snackbarMessageColor"
      rounded="pill"
    >
      {{ snackbarMessage }}
    </v-snackbar>

  </template>
  
  <script>
  import { ref, onMounted, watch } from 'vue';
  import { useStore } from 'vuex'; // Vuex 스토어 사용
  import axios from 'axios';
  import EstimateManagementPrint from '@/components/common/templete/PrintFormatEsti_rental.vue';
  import PersonalInformation from '@/components/common/policy/personal_information.vue';
  
  export default {
    components: {
      EstimateManagementPrint,
      PersonalInformation,
    },
    setup() {
        const store = useStore();
        const rightCardHeight = ref(0);
        const isMobile = ref(false);

        const insertCustNm = ref('');
        const insertMemberNm = ref('');
        const insertRentalPeriod = ref('');
        const insertCustNumberinput1 = ref('010');
        const insertCustNumberinput2 = ref('');
        const insertCustNumberinput3 = ref('');
        const insertCompanyAdress = ref('');
        const insertQustion = ref('');
        const NumberAreaCode = ref(['010','011','016','017','018','019','02','031','032','033','041','042','043','044','051','052','053','054','055','061','062','063','064','0130','070','080','0506','0505','0507','0504','0503','0502','0303','050','0508']);
        const contactCheck = ref(false);

        const cartTotalPrice = ref(0);
        const calculate_sum_item_price = () => {
            // 총금액 초기화
            cartTotalPrice.value = 0;
            // cartItems 배열 순회하여 sum_price를 합산
            for (const cartItem of cartItems.value) {
                cartTotalPrice.value += cartItem.sum_price;
            }
        };


        const snackbarOn = ref(false);
        const snackbarMessage = ref(null);
        const snackbarMessageColor = ref(null);
        const cartheaders = ref([
        { width: '100', align: 'center', title: '이미지', value: 'item_image_link'},
        { width: '100%', title: '상품명', value: 'item_nm'},
        ]);

        const calculateImgHeight = () => {
            if(isMobile.value) {
                rightCardHeight.value = window.innerHeight - 260;
            } else if (!isMobile.value) {
                rightCardHeight.value = window.innerHeight - 220;
            } else {
                rightCardHeight.value = window.innerHeight
            }
        };

        const formatPrice = (price) => {
        // price를 숫자로 파싱한 후, toLocaleString을 사용하여 한국 원 단위 포맷으로 변경
        const formattedPrice = Number(price).toLocaleString('ko-KR');
        return `${formattedPrice}`; // '원' 문자열을 추가하여 반환
        };

        const cart_item_minus_qty = (item) => {
          if (item.cart_qty > 1) {
            item.cart_qty--;
            item.sum_price = item.sale_price * item.cart_qty;
            calculate_sum_item_price();
          } 
        };

        const cart_item_plus_qty = (item) => {
          if (item.cart_qty < 100) {
            item.cart_qty++;
            item.sum_price = item.sale_price * item.cart_qty;
            calculate_sum_item_price();
          } 
        };

        const cust_info_input_dialog = ref(false);
        const open_cust_info_input_dialog = async () => {
          if (!isMobile.value) {
            if (cartItems.value.length === 0) {
              snackbarOn.value = true;
              snackbarMessage.value = '장바구니에 상품을 담아주세요';
              snackbarMessageColor.value = 'red';
            } else {
              cust_info_input_dialog.value = true;
            }
          } else {
            snackbarOn.value = true;
            snackbarMessage.value = '현재는 PC에서만 출력이 가능합니다';
            snackbarMessageColor.value = 'red';
          }
        };

        const estimate_dialog = ref(false);
        const open_estimate_dialog = async () => {
          if (insertCustNm.value === '' || insertCustNumberinput2.value === '' || insertCustNumberinput3.value === '' || insertMemberNm.value === '' || insertRentalPeriod.value === '') {
            snackbarOn.value = true;
            snackbarMessage.value = '고객정보가 입력되지않았습니다';
            snackbarMessageColor.value = 'red';
          } else if (!contactCheck.value) {
            snackbarOn.value = true;
            snackbarMessage.value = '개인정보 수집에 동의해주세요';
            snackbarMessageColor.value = 'red';
          } else {
            cust_info_input_dialog.value = false;
            estimate_dialog.value = true;
          }
        };

        const cartItems = ref([]);
        const filteredItems = ref([]);
        const add_cart_item = (item) => {
          if (cartItems.value.length >= 18) {
            snackbarOn.value = 'true';
            snackbarMessage.value = '장바구니의 최대수량을 초과하였습니다';
            snackbarMessageColor.value = 'red-darken-2';
            return;
          }

          console.log(item);
          const { item_id, item_sc, sale_item_nm, sale_price, qty, item_image_link, item_detail, item_detail_image_link } = item;

          let category;
          let item_name;

          if (item.item_brand === '조립컴퓨터') {
            category = item.item_brand;
            item_name = item.item_nm;
          } else {
            const parts = sale_item_nm.split('] ');
            category = parts[0].replace('[', '');
            item_name = parts[1];
          }

          const existingCartItemIndex = cartItems.value.findIndex(cartItem => cartItem.item_id === item_id);

          if (existingCartItemIndex !== -1) {
            // 동일한 item_id가 이미 장바구니에 있는 경우, 수량을 증가시킵니다.
            cartItems.value[existingCartItemIndex].cart_qty += 1;
            cartItems.value[existingCartItemIndex].sum_price = cartItems.value[existingCartItemIndex].sale_price * cartItems.value[existingCartItemIndex].cart_qty;
          } else {
            // 동일한 item_id가 장바구니에 없는 경우, 새로운 항목을 추가합니다.
            const cart_qty = 1;
            const cartItem = {
              item_id,
              item_sc,
              item_image_link,
              item_category: category,
              item_nm: item_name,
              sale_price,
              qty,
              item_detail,
              item_detail_image_link,
              cart_qty: cart_qty,
              sum_price: sale_price * cart_qty,
              filteredItemIndex: -1,
            };

            // filteredItems에서 해당 item의 인덱스를 찾습니다.
            const index = filteredItems.value.findIndex(filteredItem => filteredItem.item_sc === item_sc);
            if (index !== -1) {
              cartItem.filteredItemIndex = index; // 인덱스를 cartItem에 기록합니다.
              filteredItems.value.splice(index, 1); // 해당 아이템을 filteredItems 배열에서 제거합니다.
            }

            // cartItem을 cartItems 배열에 추가합니다.
            cartItems.value.push(cartItem);
          }

          calculate_sum_item_price();
          snackbarOn.value = 'true';
          snackbarMessage.value = '장바구니에 추가되었습니다';
          snackbarMessageColor.value = 'blue';
        };


        const cancel_cart_item = (item) => {
          // 취소할 아이템의 정보 추출
          const { item_id, item_sc, item_nm, sale_price, qty, item_image_link, item_detail, filteredItemIndex } = item;
          // filteredItems에 다시 추가할 아이템 객체 생성
          const filteredItem = {
            item_id,
            item_sc,
            item_image_link,
            item_nm,
            sale_price,
            qty,
            item_detail,
          };

          // cartItems 배열에서 해당 아이템 제거
          const index = cartItems.value.findIndex(cartItem => cartItem.item_sc === item_sc);
          if (index !== -1) {
            cartItems.value.splice(index, 1);
          }

          // filteredItems 배열에 다시 추가
          if (filteredItemIndex !== -1) {
            // filteredItemIndex에 지정된 위치에 아이템 추가
            filteredItems.value.splice(filteredItemIndex, 0, filteredItem);
          } else {
            // filteredItemIndex가 -1이라면 가장 끝에 추가
            filteredItems.value.push(filteredItem);
          }
        };

        const sendEmail = async () => {
          const sendCartItems = cartItems.value;

          if (insertCustNm.value === '' || insertCustNumberinput2.value === '' || insertCustNumberinput3.value === '') {
            snackbarOn.value = true;
            snackbarMessage.value = '고객정보가 입력되지않았습니다';
            snackbarMessageColor.value = 'red';
            return;
          }
          if (!contactCheck.value) {
            snackbarOn.value = true;
            snackbarMessage.value = '개인정보 수집에 동의해주세요';
            snackbarMessageColor.value = 'red';
            return;
          }

          if (!insertCompanyAdress.value) {
            snackbarOn.value = true;
            snackbarMessage.value = '간략한 사업장 소재지를 입력해주세요';
            snackbarMessageColor.value = 'red';
            return;
          }

          if (!insertQustion.value) {
            snackbarOn.value = true;
            snackbarMessage.value = '문의내용을 입력하세요';
            snackbarMessageColor.value = 'red';
            return;
          }

          try {
            loading.value = true;

            const itemsString = sendCartItems.map(item => 
              `상품코드: ${item.item_sc}, 상품명: ${item.item_nm}, 신청수량: ${item.cart_qty}, 카테고리: ${item.item_category}, 렌탈비: ${item.sale_price}`
            ).join('\n');

            const contents = `
              회사명: ${insertCustNm.value}
              담당자명: ${insertMemberNm.value}
              렌탈기간: ${insertRentalPeriod.value}
              전화번호: ${insertCustNumberinput1.value+'-'+insertCustNumberinput2.value+'-'+insertCustNumberinput3.value}
              문의종류: ${`렌탈상담신청`}
              사업장위치: ${insertCompanyAdress.value}
              문의내용: ${insertQustion.value}
              문의상품: ${itemsString}
            `;
            const response = await axios.post('https://api.samjinnetworks.com/api/web/www/contact/email/send', {
              contents: contents
            });
            console.log(response.data);
            
            insertCustNm.value = '';
            insertCustNumberinput2.value = '';
            insertCustNumberinput3.value = '';
            snackbarMessageColor.value = 'success'
            snackbarMessage.value = '문의내용이 정상적으로 등록되었습니다';
            snackbarOn.value = true;

          } catch (error) {
            console.error(error.response.data);
            // 이메일 전송에 실패한 경우 사용자에게 알릴 수 있음
          } finally {
            loading.value = false;
          }
        };

        const loading = ref(true);
        const policy_personal_information = ref(false);
        const policy_personal_informationToggle = () => {
          policy_personal_information.value = !policy_personal_information.value
        };
  
      onMounted(() => {
        calculateImgHeight();
        loading.value = false;
      });


  
      // watch 사용하여 store의 getPcNo getter를 감시
      watch(
        () => store.getters.getItems, // 감시할 getter
        (newItems) => {
            // getter의 값이 변경될 때 실행되는 콜백 함수
            if (newItems.length !== 0) {
                add_cart_item(newItems);
                store.dispatch('setItems', []);
                console.log(newItems);
            }
        },
        { immediate: true } // 현재 값도 즉시 호출
      );


  
      return {
        rightCardHeight, isMobile, insertCustNm, insertCustNumberinput1, insertCustNumberinput2, insertCustNumberinput3,
        NumberAreaCode, contactCheck, cartItems, snackbarOn, snackbarMessage, snackbarMessageColor,
        calculateImgHeight, formatPrice, filteredItems, cartTotalPrice, calculate_sum_item_price,
        cartheaders, cart_item_minus_qty, cart_item_plus_qty, cust_info_input_dialog, open_cust_info_input_dialog,
        open_estimate_dialog, estimate_dialog, sendEmail, insertMemberNm, insertRentalPeriod,
        insertCompanyAdress, insertQustion, cancel_cart_item, loading, policy_personal_information, policy_personal_informationToggle,
      };
    }
  };
  </script>
  