<template>
<v-card title="개인정보취급방침"
    style="max-width: 500px;"
>
    <template v-slot:text>
      <div style="font-size: 14px;">
        <b>- 수집항목:</b>
        <br> 성명, 전화번호, 주소<br><br>
        <b>- 수집/이용목적:</b>
        <br> 서비스 제공 및 계약의 이행, 구매 및 대금결제, 물품배송 또는 청구지 발송, 불만처리 등 민원처리, 회원관리 등을 위한 목적<br><br>
        <b>- 이용기간:</b>
        <br> 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보전할 필요가 있는 경우 일정기간 동안 개인정보를 보관할 수 있습니다.
      </div>
    </template>
  </v-card>
</template>